<script setup lang="ts">
import { ref, watchEffect } from "vue";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

// ! This Component is highly reactive based on props, so be careful when using it

const props = defineProps<{
  apiCall: () => Promise<[boolean, any]>;
  shouldMakeApiCall: boolean;
  shouldShowContent: boolean;
  errorObj: { text: string; icon: string };
}>();

const loading = ref(true);
const apiSuccess = ref(false);

const makeSuccessState = () => {
  apiSuccess.value = true;
  loading.value = false;
};
const makeErrorState = () => {
  loading.value = false;
  apiSuccess.value = false;
};

const makeAsyncTask = async () => {
  try {
    const [err, _res] = await props.apiCall();
    makeSuccessState();

    if (err) makeErrorState();
  } catch (err) {
    makeErrorState();
  }
};

watchEffect(() => {
  if (props.shouldMakeApiCall) makeAsyncTask();
});
watchEffect(() => {
  if (props.shouldShowContent) makeSuccessState();
});
</script>

<template>
  <div v-if="loading" class="custom-suspense-container">
    <LoaderSpinner />
  </div>
  <div v-if="!apiSuccess && !loading" class="error-container">
    <div class="icon">
      <img :src="errorObj.icon" alt="error" />
    </div>
    <div class="text">{{ errorObj.text }}</div>
  </div>
  <slot v-if="apiSuccess && !loading" name="content"></slot>
</template>

<style scoped>
@import "@/assets/base.css";
.custom-suspense-container {
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.error-container .icon {
  width: 2em;
  height: 2em;
  margin-bottom: 1em;
}
.error-container .icon img {
  width: 100%;
  height: 100%;
}
.error-container .text {
  font-size: 1rem;
  color: var(--clr-red-3);
  width: 70%;
  text-align: center;
}

@media (--max-phone-width) {
  .error-container .text {
    font-size: 1em;
    width: 90%;
  }
}
</style>
