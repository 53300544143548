export enum QuestionType {
  FREE_ANSWER = "free_answer",
  TEXT_INPUT = "text_input",
  PREDEFINED_ANSWERS = "predefined_answers",
  EQUIPMENT = "equipment",
  BIRTHDAY = "birthday",
  PHONE_NUMBER = "phone-number",
  HEIGHT = "height",
  WEIGHT = "weight",
  AVATAR = "avatar",
}
