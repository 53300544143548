import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";

type StatusMarkerTypeKebabCase = "critical-low" | "low" | "lower" | "optimal" | "higher" | "high" | "critical-high";

export enum StatusMarkerType {
  CRITICAL_LOW = "criticalLow",
  LOW = "low",
  LOWER = "lower",
  OPTIMAL = "optimal",
  HIGHER = "higher",
  HIGH = "high",
  CRITICAL_HIGH = "criticalHigh",
}
export const StatusMarkerTypeKeys: { [key in StatusMarkerTypeKebabCase]: StatusMarkerType } = {
  "critical-low": StatusMarkerType.CRITICAL_LOW,
  low: StatusMarkerType.LOW,
  lower: StatusMarkerType.LOWER,
  optimal: StatusMarkerType.OPTIMAL,
  higher: StatusMarkerType.HIGHER,
  high: StatusMarkerType.HIGH,
  "critical-high": StatusMarkerType.CRITICAL_HIGH,
};
export const StatusMarkerClass: { [key in StatusMarkerType]: string } = {
  criticalLow: "critical-low",
  low: "low",
  lower: "lower",
  optimal: "optimal",
  higher: "higher",
  high: "high",
  criticalHigh: "critical-high",
};

export interface BloodTests {
  totalCount: number | null;
  results: BloodTestShort[];
}

export interface BloodTestShort {
  id: number;
  type: string;
  state: "draft" | "published";
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  laboratory: string;
  markers: Marker[];
}

export interface BloodTestFull {
  id: number;
  type: string;
  state: "draft" | "published";
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  laboratory: string;
  bloodMarkerResults: MarkerShort[];
  healthAdvices: {
    medical: HealthAdviceMedical[];
    nutritional: HealthAdviceNutritional[];
  };
}

export interface MarkerShort {
  id: number;
  bloodMarker: {
    id: number;
    name: string;
  };
  value: string;
  unit: string;
  range: StatusMarkerTypeKebabCase;
  referenceValues: { [key in StatusMarkerType]: { min: string | null; max: string | null } };
}

export interface Marker {
  id: number;
  bloodMarker: {
    id: number;
    name: string;
    description: string;
    lowLevelSymptoms: string;
    highLevelSymptoms: string;
  };
  value: string;
  unit: string;
  range: StatusMarkerTypeKebabCase;
  referenceValues: any;
  previousResults: {
    date: string;
    value: number;
    number: number;
  }[];
}

export interface HealthAdviceMedical {
  id: number;
  key: string;
  description: string;
  type: string;
  adviceGroups: string[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface HealthAdviceNutritional {
  id: string;
  key: string;
  description: string;
  type: string;
  adviceGroups: string[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface BloodTestResults {
  [key: number]: BloodTestFull;
}

export const useCheckupResultsStore = defineStore({
  id: "checkup-results",
  state: () => ({
    bloodTests: {
      totalCount: null as number | null,
      results: [] as BloodTestShort[],
    } as BloodTests,
    bloodTestResults: {} as BloodTestResults, // the key used for index here is the bloodTestId
    marker: null as Marker | null,
    bloodTestUploadCredits: 0 as number | null, // we use null if there is an error on API
  }),
  getters: {},
  actions: {
    async fetchBloodTests(): Promise<[boolean, BloodTests | null]> {
      if (this.bloodTests.totalCount !== null) return [false, this.bloodTests];
      try {
        const res = await axiosInstance.get(`${API.BLOOD.RESULTS}?filters[state_eq]=published`);
        const data: BloodTests = camelcaseKeys(res.data, { deep: true });
        this.bloodTests.totalCount = data.totalCount;
        this.bloodTests.results = data.results;
        return [false, data];
      } catch (err) {
        console.error("Error on GET /blood-results API, ", err);
        return [true, null];
      }
    },
    async fetchBloodTest(bloodTestId: number): Promise<[boolean, BloodTestFull | null]> {
      const btr = this.bloodTestResults[bloodTestId];
      if (btr) return [false, btr];
      try {
        const res = await axiosInstance.get(API.BLOOD.RESULT.replace("{id}", bloodTestId.toString()));
        const data: BloodTestFull = camelcaseKeys(res.data, { deep: true });
        this.bloodTestResults[bloodTestId] = data;
        return [false, data];
      } catch (err) {
        console.error(`Error on GET /blood-tests/results/${bloodTestId} `, err);
        return [true, null];
      }
    },
    async fetchMarker(markerId: number, bloodTestId: number): Promise<[boolean, Marker | null]> {
      if (markerId === this.marker?.id) return [false, this.marker];

      try {
        const res = await axiosInstance.get(
          API.BLOOD.MARKER.replace("{blood_test_id}", bloodTestId.toString()).replace(
            "{blood_marker_result_id}",
            markerId.toString()
          )
        );
        const data: Marker = camelcaseKeys(res.data, { deep: true });
        this.marker = data;
        return [false, data];
      } catch (err) {
        console.error("Error on GET /marker API, ", err);
        return [true, null];
      }
    },
    async uploadBloodTest(file: any) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await axiosInstance.post(
          API.BLOOD_TEST_UPLOAD,
          { document: file },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        return [false, res];
      } catch (err) {
        console.error("Error on POST /blood_tests/upload API, ", err);
        return [true, null];
      }
    },
    async getBloodTestsUploadCredits(): Promise<[null | true, null | number]> {
      try {
        const res = await axiosInstance.get(API.BLOOD_TEST_UPLOAD_CREDITS);
        const data: number = camelcaseKeys(res.data).bloodTestUploadCredits;
        this.bloodTestUploadCredits = data;
        return [null, data];
      } catch (err) {
        console.error("Error fetching the remaining upload credits, ", err);
        this.bloodTestUploadCredits = null;
        return [true, null];
      }
    },
  },
});
