<script setup lang="ts">
import { ref, useSlots } from "vue";

const slots = useSlots();
const opened = ref<boolean>(true);
</script>

<template>
  <div class="detail-layout">
    <div v-if="slots.title" :class="{ row: true, 'margin-bot': opened }" @click="opened = !opened">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div :class="{ 'arrow-cont': true, closed: !opened }">
        <img src="@/assets/icons/arrow-in-circle-blue.svg" />
      </div>
    </div>
    <div v-if="opened" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.detail-layout {
  border-radius: 1em;
  position: relative;
  padding: 1em 0.5em;
}
.detail-layout:not(:last-child):before {
  content: "";
  position: absolute;
  left: 0.5em;
  bottom: 0;
  height: 1px;
  width: calc(100% - 1em);
  border-bottom: 1px solid var(--clr-grey-2);
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & .title {
    font-weight: bold;
    font-size: 1.1rem;
  }
}
.row.margin-bot {
  margin-bottom: 1em;
}
.arrow-cont {
  width: 1.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.arrow-cont img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}
.arrow-cont.closed img {
  transform: rotate(180deg);
}
.content {
  margin-left: 1em;
  font-size: 0.9rem;
  @media (--max-tablet-width) {
    margin-left: 0;
  }
}
.simple-item {
  width: 100%;
  cursor: pointer;
}
</style>
