<script setup lang="ts">
import { ref, nextTick, useSlots } from "vue";
import { useEventListener } from "@/composables/useEventListener";
import { useRoute } from "vue-router";
import { RouteQuery } from "@/types/RouteTypes";
import { useToggleableListItemMainEvents, TopRowSlotButtons } from "@/composables/ui/useToggleableListItemMainEvents";

const route = useRoute();
const slots = useSlots();

const props = defineProps<{
  elementId: number;
}>();

const emit = defineEmits<{
  (e: "layoutToggled", { elementId, val }: { elementId: number; val: boolean }): void;
  (e: "rowButtonOneClicked"): void;
}>();

// * This is used for animations purposes, and as an approx. height dimension, but if I don't use it
// * and I set the height of the .content element with fit-content for example, the animation doesn't
// * have a smooth transition, so that's that 🤷‍♂️
const itemLayoutContainer = ref<HTMLDivElement | null>(null);

const contentElement = ref<HTMLDivElement | null>(null);
const contentOpened = ref(false);
const contentShowAll = ref(false);
const hasTopRowButtons = ref(false);

const { loadableButtons, clickRowButtonOne } = useToggleableListItemMainEvents(emit);

const updateHasShowAllBtn = (transitionEndEvent: Event) => {
  if (!contentElement.value) return;
  if (transitionEndEvent.target !== contentElement.value) return;

  hasTopRowButtons.value = contentElement.value!.clientHeight < contentElement.value!.scrollHeight;
};

const resetState = () => {
  setContentHeight(0);
  contentShowAll.value = false;
  contentOpened.value = false;
  hasTopRowButtons.value = false;
};

const toggleShowContent = () => {
  contentOpened.value = !contentOpened.value;
};

const showAllContent = async (e: Event) => {
  e.stopPropagation();
  contentShowAll.value = true;
  await nextTick();
};

const getElementHeight = (el: HTMLDivElement) => {
  return el.getBoundingClientRect().height;
};

const setContentHeight = (to: number) => {
  if (!to) return contentElement.value!.style.removeProperty("max-height");
  (contentElement.value!.style as any)["max-height"] = `${to}px`;
};

const emitLayoutToggle = () => {
  emit("layoutToggled", { elementId: props.elementId, val: contentOpened.value });
};

useEventListener(window, "transitionend", updateHasShowAllBtn);

const pipe =
  (f: (event: any) => Promise<void>, g: (el: HTMLDivElement) => number, h: (height: number) => void) => async () => (
    // @ts-ignore
    await f(), h(g())
  );
const showAllAndSetHeight = (e: Event) =>
  pipe(showAllContent.bind(null, e), getElementHeight.bind(null, itemLayoutContainer.value!), setContentHeight)();

// ----- Show Content immediatelly if Route says so -----
const openLayoutIfRouteContainsCorrectNr = () => {
  if (!Object.keys(route.query).length || !route.query[RouteQuery.ELEMENT_ID]) return;
  if (parseInt(route.query[RouteQuery.ELEMENT_ID] as string) === props.elementId) contentOpened.value = true;
};

openLayoutIfRouteContainsCorrectNr();
// ----- Show Content immediatelly if Route says so -----
</script>

<template>
  <div :class="{ 'item-layout-container': true, opened: contentOpened }" ref="itemLayoutContainer">
    <div
      class="top-row"
      @click="
        () => ((hasTopRowButtons = false), toggleShowContent(), !contentOpened && resetState(), emitLayoutToggle())
      "
    >
      <div class="left">
        <div class="item-nr"><slot name="title"></slot></div>
        <div class="item-date"><slot name="date"></slot></div>
      </div>
      <div :class="{ right: true, closed: !contentOpened }">
        <button
          v-if="contentOpened && hasTopRowButtons && slots[TopRowSlotButtons.BUTTON_ONE]"
          :class="{ 'top-row-button': true, 'btn-loading': loadableButtons[TopRowSlotButtons.BUTTON_ONE] }"
          :disabled="loadableButtons[TopRowSlotButtons.BUTTON_ONE]"
          @click="clickRowButtonOne"
        >
          <slot name="row-button-one"></slot>
        </button>
        <button
          v-if="contentOpened && !contentShowAll && hasTopRowButtons"
          class="top-row-button"
          @click="showAllAndSetHeight"
        >
          {{ $t("ACTIONS.SHOW_ALL") }}
        </button>
        <div :class="{ 'icon-cont': true, closed: !contentOpened }">
          <img src="@/assets/icons/arrow-in-circle-blue.svg" alt="arrow" />
        </div>
      </div>
    </div>
    <div :class="{ content: true, opened: contentOpened, 'show-all': contentShowAll }" ref="contentElement">
      <slot name="main-content"></slot>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/base.css";
@import "@/assets/styles/ui.css";
@import "@/assets/styles/buttons.css";

.item-layout-container {
  background-color: var(--clr-white-1);
  padding: 1em;
  border-radius: 1em;
  margin-bottom: 1.5em;
  box-shadow: 0 10px 20px -2px var(--clr-grey-3);
}
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  cursor: pointer;
  & .icon-cont {
    width: 2.2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 100%;
      height: 100%;
      user-select: none;
      transition: transform 0.3s ease;
    }
  }
  & .icon-cont.closed {
    & img {
      transform: rotate(180deg);
    }
  }
  & .item-nr {
    margin-right: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
  }
  & .item-date {
    font-size: 0.9em;
    color: var(--clr-blue-1);
    font-weight: bold;
  }
  & .left {
    display: flex;
    align-items: flex-end;
    flex: 3;
  }
  & .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 4;
    & .top-row-button {
      background-color: var(--clr-white-1);
      cursor: pointer;
      user-select: none;
      border: 1px solid var(--clr-blue-1-semi);
      padding: 0.5em;
      border-radius: 1.5em;
      display: flex;
      align-items: center;
      color: var(--clr-blue-1-semi);
      font-size: 0.9em;
      margin-right: 1em;
      position: relative;
    }
    & .top-row-button:active {
      background-color: var(--clr-white-2);
    }
    @mixin buttonLoading .top-row-button
  }
}

.content {
  transition: all 0.3s ease-out;
  max-height: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: var(--cards-gap);
  overflow-y: scroll;
}
@supports (row-gap: var(--cards-gap)) {
  .content {
    row-gap: var(--cards-gap);
  }
}
.content.opened {
  max-height: 30em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.content :deep(.custom-suspense-container .loader-spinner) {
  top: 25%;
  width: 80px;
  height: 80px;
}
.content.opened.show-all {
  max-height: fit-content;
}

@mixin scrollbar-one .content;

@media (--max-tablet-width) {
  .top-row {
    & .item-nr {
      font-size: 1em;
    }
    & .item-date {
      font-size: 0.8em;
    }
    & .right {
      & .top-row-button {
        margin-right: 0.5em;
        font-size: 0.8em;
      }
    }
  }
}
@media (--max-phone-width) {
  .top-row {
    & .left {
      flex: 1;
    }
    & .right {
      flex: 1;
    }
  }
}
</style>
