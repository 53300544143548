<script setup lang="ts">
import { ref, computed, type Ref, onMounted } from "vue";
import { usePopupBox } from "@/composables/ui/usePopupBox";
import notBell from "@/assets/logos/notification-bell.svg";
import notBellActive from "@/assets/logos/notification-bell-active.svg";
import PopupBox from "@/components/ui/PopupBox.vue";
import MessageList from "./MessageList.vue";
import { useMessagesStore } from "@/stores/messages";

const messagesStore = useMessagesStore();

const notificationIconSrc = computed<string>(() => {
  return messagesStore.totalCount ? notBellActive : notBell;
});

onMounted(async () => {
  messagesStore.fetchMessages();
});

// ----- Popup -----
const teleportTo = "body";
const notIcon = ref<HTMLElement | null>(null);
const { showPopupBox, togglePopupBox, closePopupBox, clickPosition, classDown } = usePopupBox(
  teleportTo,
  notIcon as Ref<HTMLElement>,
  true
);
// ----- Popup -----
</script>

<template>
  <div class="notification-container">
    <div class="notification-img" ref="notIcon" @click="togglePopupBox">
      <img :src="notificationIconSrc" alt="notification" />
    </div>
    <Teleport :to="teleportTo">
      <Transition name="modal">
        <PopupBox
          v-if="showPopupBox"
          v-click-outside:[notIcon]="closePopupBox"
          :classDown="classDown"
          :position="clickPosition"
          @closePopup="closePopupBox"
        >
          <MessageList />
        </PopupBox>
      </Transition>
    </Teleport>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/navbars.css";

.notification-img {
  width: var(--infobar-icon-width);
  height: var(--infobar-icon-width);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.notification-container img {
  width: inherit;
  height: inherit;
}

@media (--max-phone-width) {
  .notification-img {
    height: var(--infobar-icon-width-phone);
    width: var(--infobar-icon-width-phone);
  }
  .popup-content {
    font-size: 0.9em;
  }

  .modal-leave-active,
  .modal-enter-active {
    transition: all 0.3s;
  }
  .modal-leave-to,
  .modal-enter-from {
    transform: translate(0, 100%) !important;
  }
}
</style>
