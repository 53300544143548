<script setup lang="ts">
import { ref, computed } from "vue";
import { isMobileBrowser } from "@/util/functions";
import MagicLinkMobile from "@/components/layout/MagicLinkMobile.vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

const route = useRoute();
const authStore = useAuthStore();
const loading = ref(true);

const isMobile = computed<boolean>(() => {
  return isMobileBrowser();
});

const handleDesktop = async () => {
  const token = route.query.token as string;
  const [err, res]: any[] = await authStore.confirmSignIn(token);
  loading.value = false;

  if (err) return authStore.redirectTo(RouteName.AUTH_ERROR_MAGIC);
  if (res) authStore.redirectTo(RouteNameMenu.MENU_ANALYTICS);
};

const handleMobileOrDesktop = () => {
  if (isMobile.value) return (loading.value = false);
  handleDesktop();
};

handleMobileOrDesktop();
</script>

<template>
  <div class="auth-signing-in">
    <div class="loader-container" v-if="loading">
      <LoaderSpinner />
    </div>
    <MagicLinkMobile v-if="isMobile && !loading"></MagicLinkMobile>
  </div>
</template>

<style scoped>
.auth-signing-in {
  height: 100vh;
  display: flex;
}
.loader-container {
  position: relative;
  width: 100%;
}
</style>
