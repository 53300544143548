import { HeightImperialUnit, WeightUnit } from "@/stores/member";
import isAlpha from "validator/es/lib/isAlpha";
import isAlphanumeric from "validator/es/lib/isAlphanumeric";

export const validateHeightFeet = (val: string) => {
  const parsedVal = parseFloat(val);
  return parsedVal >= 2 && parsedVal <= 8;
};

export const validateHeightInches = (val: string) => {
  const parsedVal = parseFloat(val);
  return parsedVal >= 0 && parsedVal < 12;
};

export const validateHeight = (value: string): boolean => {
  if (!value) return false;
  return parseFloat(value) >= 50 && parseFloat(value) < 250;
};

export const validateHeightImperial = (value: { [key in HeightImperialUnit]: string }): {
  [key in HeightImperialUnit]: boolean;
} => {
  const val = value as { [key in HeightImperialUnit]: string };
  return {
    feet: !validateHeightFeet(val.feet),
    inches: !validateHeightInches(val.inches),
  };
};

export const validateWeight = (value: number | null, unit: WeightUnit): boolean => {
  if (!value) return false;
  if (unit === WeightUnit.POUNDS) {
    return value > 22 && value < 550;
  } else if (unit === WeightUnit.KG) {
    return value > 10 && value < 250;
  }
  return false as never;
};

export const validateBirthDate = (val: string): boolean => {
  const inputDate = new Date(val);
  const presentDate = new Date();
  const pastDate = new Date();
  pastDate.setFullYear(presentDate.getFullYear() - 100);
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  // date cannot be in the future
  if (!(inputDate.getTime() < presentDate.getTime())) return false;
  // date cannot be more than 100 years ago
  if (inputDate.getTime() < pastDate.getTime()) return false;
  return dateRegex.test(val);
};

export const validateString = (value: string): boolean => {
  if (!value) return false;
  return isAlpha(value);
};

export const validateAlphaNumeric = (value: string): boolean => {
  if (!value) return false;
  return isAlphanumeric(value);
};
