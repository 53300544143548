<script setup lang="ts">
import type { StatusMarkerType } from "@/stores/checkup-results";
import { useStatusUI } from "@/composables/useStatusUI";

const props = defineProps<{
  status: StatusMarkerType;
}>();

const { iconType, elementClass } = useStatusUI(props.status, false);
</script>

<template>
  <div :class="['status-icon-cont', elementClass]">
    <div class="img-cont">
      <component :is="iconType.component" v-bind="iconType.props" />
    </div>
    <span class="text">{{ iconType.text }}</span>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.status-icon-cont {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.3em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 1em;
}
.status-icon-cont.critical-low {
  background-color: var(--clr-status-ic-critical-low);
}
.status-icon-cont.low {
  background-color: var(--clr-status-ic-low);
}
.status-icon-cont.lower {
  background-color: var(--clr-status-ic-lower);
}
.status-icon-cont.optimal {
  background-color: var(--clr-status-ic-optimal);
}
.status-icon-cont.higher {
  background-color: var(--clr-status-ic-higher);
}
.status-icon-cont.high {
  background-color: var(--clr-status-ic-high);
}
.status-icon-cont.critical-high {
  background-color: var(--clr-status-ic-critical-high);
}

.img-cont {
  width: 1.2em;
  height: 1.2em;
  display: flex;
}
.text {
  color: var(--clr-white-1);
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-left: 0.5em;
}
</style>
