<script setup lang="ts">
import camelcaseKeys from "camelcase-keys";
import { axiosInstance } from "@/util/api/axios_instance";
import { API } from "@/util/api/api_paths";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

const route = useRoute();
const loading = ref(true);
const error = ref(false);

onMounted(async () => {
  const token = route.query.token as string;
  try {
    const res = await axiosInstance.post(API.MANAGE_PAYMENT, { token });
    const data = camelcaseKeys(res.data, { deep: true });

    const paymentPageUrl = data.managePaymentMethodUrl;
    window.location.href = paymentPageUrl;
  } catch (err) {
    console.error("Error on the /subscription/finalize API, ", err);
    error.value = true;
  }

  loading.value = false;
});
</script>

<template>
  <div class="manage-payment">
    <div class="bioniq-logo">
      <img src="@/assets/logos/bioniq-logo.svg" alt="bioniq logo" />
    </div>
    <div class="loader-container" v-if="loading">
      <LoaderSpinner />
    </div>
    <div v-if="error" class="error-cont">
      <div class="title">{{ $t("MANAGE_PAYMENT.ERROR.TITLE") }}</div>
      <div class="text">{{ $t("MANAGE_PAYMENT.ERROR.TEXT") }}</div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/callback-pages-web-only.css";

@mixin callback-web-page-mixin;

.manage-payment {
  height: 100vh;
}
</style>
