<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import errorImg from "@/assets/icons/error-1.webp";
import { ref, watchEffect, computed, defineAsyncComponent, onUnmounted } from "vue";
import { useFormulasStore, type FormulasComplete, type Formulas } from "@/stores/formulas";
import CustomSuspense from "@/components/helpers/CustomSuspense.vue";
import ToggleableListItemMain from "@/components/ui/ToggleableListItemMain.vue";
import { useToggleableListItemMain } from "@/composables/ui/useToggleableListItemMain";
import { useFormula } from "@/composables/useFormula";
import { createdAtToDateString } from "@/util/dates";
import { emitter, EmmitEvents } from "@/includes/emmiter";
import { isLimitedAppFunctionality } from "@/util/functions";
const FormulaRecipe = defineAsyncComponent(() => import("@/components/formulas/FormulaRecipe.vue"));

const { t } = useI18n();
const formulaStore = useFormulasStore();

const { formulaTitleByIndex } = useFormula();

const { formulas, formulasComplete } = storeToRefs(formulaStore);
const { listItemLayoutToggled, listItemsHandler: formulasHandler } = useToggleableListItemMain<
  Formulas,
  FormulasComplete
>(formulas, formulasComplete);

const errorFetchFormulaComplete = {
  text: t("SECTION.FORMULAS.ERRORS.FORMULAS_API_TESTS", { data: t("SECTION.FORMULAS.FORMULA.SUPPLEMENT_FORMULA", 1) }),
  icon: errorImg,
};

// ----- All Formulas Handlers -----
const makeFormulasApiCall = ref(false);
const errorFetchFormulas = {
  text: t("SECTION.FORMULAS.ERRORS.FORMULAS_API_TESTS", { data: t("SECTION.FORMULAS.FORMULA.SUPPLEMENT_FORMULA", 2) }),
  icon: errorImg,
};

const filteredFormulas = computed(() => {
  return formulas.value;
});

const formulasEmpty = computed<boolean>(() => formulas.value.totalCount === null);

const checkIfFormulasAreLoaded = () => {
  makeFormulasApiCall.value = formulasEmpty.value ? true : false;
};

watchEffect(() => checkIfFormulasAreLoaded());
// ----- All Formulas Handlers -----

// ----- Export Formula to PDF -----
const buttonLoading = ref<boolean>(false);

const startExportingRecipeToPDF = (formulaId: number) => {
  buttonLoading.value = true;
  emitter.emit(EmmitEvents.EXPORT_FORMULA_PDF, formulaId);
};

const addButtonLoadingListener = () => {
  emitter.on(EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE, resetButtonLoading);
};

const removeButtonLoadingListener = () => {
  emitter.off(EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE, resetButtonLoading);
};

const resetButtonLoading = () => {
  buttonLoading.value = false;
};

addButtonLoadingListener();
onUnmounted(() => removeButtonLoadingListener());
// ----- Export Formula to PDF -----

// ----- TODO Remove -----
import QuestionnairesClickToRedirect from "@/components/utils/QuestionnairesClickToRedirect.vue";
// ----- TODO Remove -----
</script>

<template>
  <div v-if="!isLimitedAppFunctionality()" class="formula-container">
    <CustomSuspense
      :apiCall="() => formulaStore.fetchFormulas()"
      :errorObj="errorFetchFormulas"
      :shouldMakeApiCall="makeFormulasApiCall"
      :shouldShowContent="!formulasEmpty"
    >
      <template v-slot:content>
        <div v-if="!formulas.totalCount" class="no-results-container">
          {{ $t("SECTION.FORMULAS.EMPTY") }}
        </div>
        <ToggleableListItemMain
          v-for="(formula, index) in filteredFormulas.supplementFormulas"
          :key="formula.id"
          :elementId="formula.id"
          @layoutToggled="listItemLayoutToggled"
          @rowButtonOneClicked="startExportingRecipeToPDF(formula.id)"
        >
          <template v-slot:row-button-one>
            <span :class="{ 'row-button-one__text': true, loading: buttonLoading }">{{
              t("SECTION.FORMULAS.FORMULA.EXPORT_PDF")
            }}</span>
          </template>
          <template v-slot:title>{{ formulaTitleByIndex(index) }}</template>
          <template v-slot:date>{{ createdAtToDateString(formula.createdAt) }}</template>
          <template v-slot:main-content>
            <CustomSuspense
              :apiCall="() => formulaStore.fetchFormulaComplete(formula.id)"
              :errorObj="errorFetchFormulaComplete"
              :shouldMakeApiCall="formulasHandler[formula.id].shouldMakeApiCall"
              :shouldShowContent="!!formulasComplete[formula.id]"
            >
              <template v-slot:content>
                <FormulaRecipe :formulaId="formula.id" />
              </template>
            </CustomSuspense>
          </template>
        </ToggleableListItemMain>
      </template>
    </CustomSuspense>
  </div>
  <div v-else class="limited-functionality-bg">
    <div class="limited-text">{{ $t("DEV.IN_PROGRESS") }}</div>
  </div>
  <QuestionnairesClickToRedirect />
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";

@mixin limited-func-bg;

.formula-container {
  position: relative;
  min-height: 20rem;
}
.formula-container :deep(.error-container) {
  min-height: 15em;
  width: 70%;
  margin: 0 auto;
}

.formula-container :deep(.content .custom-suspense-container) {
  min-height: 15em;
  width: 100%;
}

.formula-container {
  & .row-button-one__text {
    transition: all 0.1s;
  }
  & .row-button-one__text.loading {
    visibility: hidden;
    opacity: 0;
  }

}
</style>
