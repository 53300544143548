import { defineStore } from "pinia";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: any;
  }
}

export enum DownloadMobileAppTextKey {
  DOWNLOAD = "DOWNLOAD",
  UPLOAD_BT = "UPLOAD_BT",
}

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    appLoading: false,
    downloadMobileAppTextKey: DownloadMobileAppTextKey.DOWNLOAD as DownloadMobileAppTextKey,
  }),
  actions: {
    changeDownloadMobileAppTextKey(key: DownloadMobileAppTextKey) {
      this.downloadMobileAppTextKey = key;
    },
  },
});
