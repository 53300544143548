import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import { Gender, useMemberStore } from "@/stores/member";
import { i18n } from "@/includes/i18n-setup";
import { GenderAnswers, ProfileMandatoryQuestions, GenderIds } from "@/util/data";
import { ProfileInfoMandatoryKeys } from "@/util/constants";
import type { QuestionType } from "@/types/QuestionnaireTypes";

export enum QuestionnairesKey {
  SUPPLEMENTS = "test_radu_supplements",
  LIFESTYLE = "lifestyle_questionnaire",
  ONBOARDING = "onboarding_chatbot",
  FIRST_CLASS = "first_classes_recommendation_onboarding",
  ALGORITHM = "bioniq-algorithm-questionnaire",
}

export interface QuestionAnswer {
  id: number;
  negativeAnswer: boolean;
  predefinedValue: string | null;
  questionId: number;
  value: any;
}

export interface Question {
  availableAnswers: QuestionAnswer[];
  hasMultipleOptions: boolean;
  hasNoneOfTheAboveOption: boolean;
  hasOtherOption: any;
  id: number;
  key: string;
  maxNumberOfOptions: any;
  minNumberOfOptions: any;
  questionBody: string;
  questionType: QuestionType;
  mandatory: boolean;
}

export interface Questionnaire {
  key: QuestionnairesKey;
  name: string;
  state: "published";
  questions: Question[];
}

//* QuestionComplexType is used STRICTLY for Frontend
export enum QuestionComplexType {
  TEXT_INPUT = "text-input", // * This is used for profile info mandatory questions
  TEXTAREA = "textarea",
  CHECKBOX = "checkbox",
  RADIO = "radio",

  BIRTHDAY = "birthday", // * This is mockup for profile info mandatory questions
  PHONE_NUMBER = "phone-number", // * This is mockup for profile info mandatory questions
  HEIGHT = "height", // * This is mockup for profile info mandatory questions
  WEIGHT = "weight", // * This is mockup for profile info mandatory questions
  AVATAR = "avatar",

  RADIO_WITH_OTHER = "radio-with-other", // * This is for Radio questions with hasOtherOption free input textfield
}

export type AnswerValue = { value: string | null; answerId: number | null; prefixForOtherOptionVal?: string };

export interface Answer {
  type: QuestionComplexType;
  value: AnswerValue[] | AnswerValue;
  error: boolean;
}

export interface QuestionnaireAnswersPayload {
  questionId: number;
  availableAnswerId: number | null;
  freeAnswerValue: string | null;
}

export interface QuestionnaireAnswers {
  id: number;
  questionId: number;
  memberId: number;
  availableAnswerId: number;
  freeAnswerValue: string;
}

export const mandatoryQuestionnaireKeys: QuestionnairesKey[] = [QuestionnairesKey.ALGORITHM];

// TODO update to all keys
export const questMapper: { [key in QuestionnairesKey]?: { name: RouteName; params: { nr: number } } } = {
  test_radu_supplements: { name: RouteName.QUESTIONNAIRE_SUPPLEMENTS, params: { nr: 1 } },
  lifestyle_questionnaire: { name: RouteName.QUESTIONNAIRE_LIFESTYLE, params: { nr: 1 } },
  onboarding_chatbot: { name: RouteName.QUESTIONNAIRE_ONBOARDING_CHATBOT, params: { nr: 1 } },
  first_classes_recommendation_onboarding: { name: RouteName.QUESTIONNAIRE_FIRSTCLASSES_REC, params: { nr: 1 } },
  "bioniq-algorithm-questionnaire": { name: RouteName.QUESTIONNAIRE_ALGORITHM, params: { nr: 1 } },
};

export const useQuestionnairesStore = defineStore({
  id: "questionnaires",
  state: () => ({
    questionnaires: null as Questionnaire[] | null,
    answers: {} as { [key in QuestionnairesKey]: QuestionnaireAnswers[] },
    activeQuestionnaireKey: "" as QuestionnairesKey | "",
    erroredSteps: [] as boolean[],
    isMandQuestWithProfileQuestions: false,
  }),
  getters: {
    supplementsQuestionnaire(): Questionnaire | null {
      if (!this.questionnaires) return null;

      const supplQuest = this.questionnaires.find(
        (quest: Questionnaire) => quest.key === QuestionnairesKey.SUPPLEMENTS
      );
      return supplQuest || null;
    },
    supplementsAnswers(): QuestionnaireAnswers[] {
      return this.answers[QuestionnairesKey.SUPPLEMENTS];
    },
    activeQuestionnaire(): Questionnaire | null {
      if (!this.questionnaires) return null;
      return this.questionnaires.find((quest: Questionnaire) => quest.key === this.activeQuestionnaireKey) || null;
    },
    isMandatoryProfileInfoCompleted(): boolean {
      const memberStore = useMemberStore();
      return memberStore.isMandatoryProfileInfoCompleted;
    },
  },
  actions: {
    setIsMandQuestWithProfileQuestions(val: boolean) {
      this.isMandQuestWithProfileQuestions = val;
    },
    pushMandatoryProfileInfoQuestionsToActiveQuestionnaire() {
      this.setIsMandQuestWithProfileQuestions(true);

      [...Object.values(ProfileInfoMandatoryKeys).reverse()].forEach((value) => {
        this.activeQuestionnaire?.questions.unshift(ProfileMandatoryQuestions[value]);
      });
    },
    async fetchQuestionnaireByKey(keys: QuestionnairesKey[]) {
      try {
        const res = await axiosInstance.get(API.QUESTIONNAIRE_BY_KEY, { params: { keys } });
        const data = camelcaseKeys(res.data.questionnaires, { deep: true });
        if (!this.activeQuestionnaireKey) this.activeQuestionnaireKey = data[0].key;
        this.questionnaires = data;
        return [false, true];
      } catch (err) {
        console.error("Error calling the /questionnaires/by_key API, ", err);
        return [true, false];
      }
    },
    updateErrorSteps(steps: boolean[]) {
      this.erroredSteps = steps;
    },
    async postQuestionnaireAnswers(questionnaireKey: QuestionnairesKey, answers: Record<number, Answer>) {
      const payload: QuestionnaireAnswersPayload[] = [];

      for (const [key, value] of Object.entries(answers)) {
        const questionId = this.activeQuestionnaire?.questions[parseInt(key) - 1].id as number;
        if (value.type === QuestionComplexType.TEXTAREA) {
          payload.push({
            questionId,
            availableAnswerId: null,
            freeAnswerValue: (value.value as AnswerValue).value,
          });
        } else if (value.type === QuestionComplexType.RADIO) {
          if (!(value.value as AnswerValue).answerId) continue;
          payload.push({
            questionId,
            availableAnswerId: (value.value as AnswerValue).answerId,
            freeAnswerValue: null,
          });
        } else if (value.type === QuestionComplexType.CHECKBOX) {
          if (!(value.value as AnswerValue[]).length) continue;

          const spreadedAnswers = (value.value as AnswerValue[]).map((ansVal) => {
            return { availableAnswerId: ansVal.answerId as number, questionId, freeAnswerValue: null };
          });
          payload.push(...spreadedAnswers);
        } else if (value.type === QuestionComplexType.RADIO_WITH_OTHER) {
          if ((value.value as AnswerValue).answerId) {
            payload.push({
              questionId,
              availableAnswerId: (value.value as AnswerValue).answerId,
              freeAnswerValue: null,
            });
          } else {
            payload.push({
              questionId,
              availableAnswerId: null,
              freeAnswerValue: (value.value as AnswerValue).value,
            });
          }
        }
      }
      try {
        const res = await axiosInstance.post(API.QUESTIONNAIRE_ANSWERS, { answers: snakecaseKeys(payload) });
        const data = camelcaseKeys(res.data, { deep: true });
        this.answers[questionnaireKey] = data.memberAnswers;
        return [false, true];
      } catch (err) {
        console.error("Error calling the POST /questionnaire_answers API, ", err);
        return [true, false];
      }
    },
    async getQuestionnaireAnswers(key: QuestionnairesKey) {
      try {
        const res = await axiosInstance.get(API.QUESTIONNAIRE_ANSWERS, { params: { questionnaire_keys: [key] } });
        const data: QuestionnaireAnswers[] = camelcaseKeys(res.data.member_answers, { deep: true });
        if (!data) return [true, false];

        this.answers[key] = data;
        return [false, true];
      } catch (err) {
        console.error("Error calling the GET /questionnaire_answers API", err);
      }
    },
    computeValueForUIComponentFromAnswers(
      questionnaireKey: QuestionnairesKey,
      question: Question,
      questionType: QuestionComplexType
    ): AnswerValue | AnswerValue[] {
      // TODO this whole conditional logic based on QuestionComplexType is used in store and QuestionnaireSupplements file
      // TODO and needs some refactoring, a type pattern using a closure to encapsulate the conditional... Will have to think about it

      if (questionType === QuestionComplexType.TEXTAREA || questionType === QuestionComplexType.TEXT_INPUT) {
        const answerAlreadyExistForThisQuest = this.answers[questionnaireKey].find(
          (answer) => answer.questionId === question.id
        );
        const value: AnswerValue = { answerId: null, value: "" };
        if (!answerAlreadyExistForThisQuest) return value;

        value.value = answerAlreadyExistForThisQuest.freeAnswerValue;
        return value;
      } else if (questionType === QuestionComplexType.RADIO) {
        let answerAlreadyExistForThisQuest: QuestionnaireAnswers | undefined;

        // * ----- Mimicking answer entities for Profile Mandatory Gender Question so in the case there is an initial selection, it is selected (although this should never be the case realistically, but I like to make things complicated for no fucking reason at all 🙃)
        // TODO this should be moved somewhere else
        const memberStore = useMemberStore();
        if (question.key === ProfileInfoMandatoryKeys.GENDER) {
          if (memberStore.profile?.account.gender === Gender.MALE) {
            answerAlreadyExistForThisQuest = GenderAnswers.find(
              (answer) => answer.availableAnswerId === GenderIds.answerMale
            );
          } else if (memberStore.profile?.account.gender === Gender.FEMALE) {
            answerAlreadyExistForThisQuest = GenderAnswers.find(
              (answer) => answer.availableAnswerId === GenderIds.answerFemale
            );
          } else if (memberStore.profile?.account.gender === Gender.CRAZY) {
            answerAlreadyExistForThisQuest = GenderAnswers.find(
              (answer) => answer.availableAnswerId === GenderIds.answerNon
            );
          }
        } else {
          answerAlreadyExistForThisQuest = this.answers[questionnaireKey].find(
            (answer) => answer.questionId === question.id
          );
        }
        // * -----

        const value: AnswerValue = { answerId: null, value: "" };

        if (!answerAlreadyExistForThisQuest) return value;

        value.answerId = answerAlreadyExistForThisQuest.availableAnswerId;
        const questionAnswer = question.availableAnswers.find(
          (aa) => aa.id === answerAlreadyExistForThisQuest?.availableAnswerId
        );

        value.value = questionAnswer?.predefinedValue || null;
        return value;
      } else if (questionType === QuestionComplexType.CHECKBOX) {
        const answersAlreadyExistForThisQuest = this.answers[questionnaireKey].filter(
          (answer) => answer.questionId === question.id
        );
        const value: AnswerValue[] = [];
        if (!answersAlreadyExistForThisQuest.length) return value;

        question.availableAnswers.forEach((aa) => {
          const aaAnswerPresent = answersAlreadyExistForThisQuest.some((answer) => answer.availableAnswerId === aa.id);
          if (aaAnswerPresent) {
            // @ts-ignore
            const name = aa.value ? aa.value.translations[i18n.global.locale.value].name : aa.predefinedValue;
            value.push({ answerId: aa.id, value: name });
          }
        });
        return value;
      } else if (questionType === QuestionComplexType.RADIO_WITH_OTHER) {
        const value: AnswerValue = { answerId: null, value: "" };
        const answerAlreadyExistForThisQuest = this.answers[questionnaireKey].find(
          (answer) => answer.questionId === question.id
        );
        if (!answerAlreadyExistForThisQuest) return value;

        if (!answerAlreadyExistForThisQuest.availableAnswerId && !!answerAlreadyExistForThisQuest.freeAnswerValue) {
          // * This means user inputted custom message in that free text input field
          value.value = answerAlreadyExistForThisQuest.freeAnswerValue;
          value.prefixForOtherOptionVal = question.hasOtherOption;
          return value;
        } else {
          // * This means he preselected an option
          const questionAnswer = question.availableAnswers.find(
            (aa) => aa.id === answerAlreadyExistForThisQuest.availableAnswerId
          );
          value.answerId = answerAlreadyExistForThisQuest.availableAnswerId;
          value.value = questionAnswer?.predefinedValue || "";
          return value;
        }
      }
      return [];
    },
    async questionnaireFinished() {
      this.setIsMandQuestWithProfileQuestions(false);
      await useMemberStore().fetchMemberProfile();
      this.router.push({ name: RouteNameMenu.MENU_ANALYTICS, query: { ...this.router.currentRoute.value.query } });
    },
  },
});
