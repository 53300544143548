import { defineStore } from "pinia";
import { isMobileBrowser } from "@/util/dom/functions";

export const useLayoutStore = defineStore({
  id: "layout",
  state: () => ({
    navbarOpened: !isMobileBrowser(),
  }),
  getters: {
    isMobileBrowser() {
      return isMobileBrowser();
    },
  },
  actions: {},
});
