import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

type MessageType = "error" | "success" | "info" | "warning" | "default";

export function useToastMsg() {
  const showMessage = ({ msg, type }: { msg: string; type: MessageType }) => {
    const toast = useToast();
    toast.open({ message: msg, type, position: "bottom" });
  };

  return { showMessage };
}
