<script setup lang="ts">
import { computed, onBeforeUnmount } from "vue";
import type { RouteName } from "@/types/AuthTypes";
import { useRoute } from "vue-router";
import { useQuestionnairesStore } from "@/stores/questionnaires";
import { storeToRefs } from "pinia";

const route = useRoute();
const questStore = useQuestionnairesStore();

const props = defineProps<{
  stepsNr: number;
  questionnaireName: RouteName;
}>();

const { erroredSteps } = storeToRefs(questStore);

onBeforeUnmount(() => {
  let clearedErroredSteps = new Array(props.stepsNr).fill(false);
  questStore.updateErrorSteps(clearedErroredSteps);
});

const routeParamNr = computed<number>(() => {
  return parseInt(route.params.nr as string);
});

const stepText = (step: number) => {
  return step < 10 ? `0${step}` : step;
};
</script>
<template>
  <div class="steps-bar">
    <div class="step-with-line" v-for="step in stepsNr" :key="step">
      <div
        :class="{
          step: true,
          past: step <= routeParamNr,
          current: step === routeParamNr,
          errored: !!erroredSteps[step - 1],
        }"
      >
        <router-link :to="{ name: questionnaireName, params: { nr: step } }">
          {{ stepText(step) }}
        </router-link>
        <div v-if="step === routeParamNr" class="current-outline"></div>
      </div>
      <div :class="{ space: true, line: step < routeParamNr }" v-if="step < stepsNr"></div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.steps-bar {
  max-width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
.step-with-line {
  display: flex;
}
.step {
  border: 0.1em solid var(--clr-black-1);
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: var(--clr-white-1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.step.past {
  background-color: var(--clr-black-1);
}
.step.current {
  position: relative;
  overflow: visible;
}
.step.errored {
  border: 0.1em solid var(--clr-red-5);
}
.step.errored a {
  color: var(--clr-red-5);
}
.step .current-outline {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--clr-black-1);
  border-radius: 1em;
  transform: scale(1.4);
  z-index: -1;
  background: var(--clr-white-1);
  box-sizing: border-box;
}
.space {
  width: 1.5em;
  height: 0.1em;
  position: relative;
  top: 1em;
}
.line {
  background-color: var(--clr-black-1);
  z-index: -2;
}
.step a {
  color: var(--clr-black-1);
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step.past a {
  color: var(--clr-white-1);
}
</style>
