import { createRouter, createWebHistory } from "vue-router";
import { globalGuards } from "./guards/guards";
import { routes } from "@/router/routes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(globalGuards);

export default router;
