<script setup lang="ts">
import ButtonMain from "@/components/ui/ButtonMain.vue";
import { useAuthStore } from "@/stores/auth";
import { RouteName } from "@/types/AuthTypes";
import AuthInfo from "@/components/layout/auth/AuthInfo.vue";

const authStore = useAuthStore();

const handleNext = () => {
  authStore.redirectTo(RouteName.AUTH_EMAIL);
  authStore.resetData();
};
</script>

<template>
  <AuthInfo>
    <template v-slot:text>
      <div class="info-text">
        <span>{{ $t("LOGIN.SUCCESS.CONFIRM") }}</span>
      </div>
    </template>
    <template v-slot:button>
      <ButtonMain @click="handleNext">
        <template v-slot:btnText>{{ $t("LOGIN.SIGN_IN_AGAIN") }}</template>
      </ButtonMain>
    </template>
  </AuthInfo>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/auth.css";

@mixin info-text;

.info-text span {
  color: var(--clr-green-2);
}
</style>
