import { createI18n } from "vue-i18n";
import { LOCALE, DEFAULT_LOCALE } from "@/util/constants";
import axios from "axios";

export let i18n: any;

const getBrowserLocaleOrDefault = (): LOCALE => {
  const browserLanguage = navigator.language as LOCALE;
  if (!Object.values(LOCALE).includes(browserLanguage)) return DEFAULT_LOCALE;

  return browserLanguage;
};

export const addLanguageToI18N = (locale: LOCALE, data: any) => {
  i18n.global.setLocaleMessage(locale, data);
};

export const fetchLocale = async (locale: LOCALE) => {
  const pathLang = `${import.meta.env.BASE_URL}lang/${locale}.json`;
  try {
    const response = await axios.get(pathLang, {
      headers: {
        "content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log(`Error fetching locale ${locale},`, error);
    return null;
  }
};

export const initializeTranslations = async () => {
  const locale = getBrowserLocaleOrDefault();
  const res = await fetchLocale(locale);

  i18n = createI18n({
    legacy: false,
    locale: locale, // set locale
    fallbackLocale: DEFAULT_LOCALE,
    messages: { [locale]: res }, // set locale messages
  });
  return i18n;
};
