<script setup lang="ts">
import AuthCheckEmail from "@/components/layout/auth/AuthCheckEmail.vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
</script>

<template>
  <AuthCheckEmail>
    <template v-slot:text>
      <i18n-t class="info-text" keypath="LOGIN.SIGN_IN_CONFIRM" tag="div">
        <span>{{ authStore.email || "johndoe@gmail.com" }}</span>
      </i18n-t>
    </template>
  </AuthCheckEmail>
</template>

<style scoped>
@import "@/assets/styles/auth.css";

@mixin info-text;
</style>
