<script setup lang="ts">
import { useMemberStore } from "@/stores/member";
import { dynamicQuestionnaireRoute } from "@/router/guards/questionnaires";
import { QuestionnairesKey, questMapper } from "@/stores/questionnaires";
import { useRouter } from "vue-router";

const memberStore = useMemberStore();
const router = useRouter();

const navigateTo = (key: QuestionnairesKey) => {
  const pushTo = dynamicQuestionnaireRoute(key);
  router.push(pushTo);
};
</script>

<template>
  <div class="for-ana">
    <h3>For Ana</h3>
    <div
      v-for="questionnaire in memberStore.profile!.questionnaires"
      class="questionnare-link"
      :key="questionnaire.key"
    >
      <div v-if="questMapper[questionnaire.key]" class="link" @click="navigateTo(questionnaire.key)">
        {{ questionnaire.key }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
.questionnare-link {
  cursor: pointer;
}
.questionnare-link:hover {
  opacity: 0.5;
}
</style>
