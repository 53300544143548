import { ref, onUnmounted } from "vue";
import { emitter, EmmitEvents } from "@/includes/emmiter";

export enum TopRowSlotButtons {
  BUTTON_ONE = "row-button-one",
}

export function useToggleableListItemMainEvents(vueEmit: any) {
  // ----- Top Row Slot Buttons and Events-----
  const loadableButtons = ref<{ [key in TopRowSlotButtons]: boolean }>({
    [TopRowSlotButtons.BUTTON_ONE]: false,
  });

  const clickRowButtonOne = (event: MouseEvent) => {
    event.stopPropagation();

    loadableButtons.value[TopRowSlotButtons.BUTTON_ONE] = true;
    emitter.emit(EmmitEvents.LOADING_TOGGL_LIST_ITEM_MAIN_BTN_ONE);
    vueEmit("rowButtonOneClicked");
  };

  const addLoadableButtonsListeners = () => {
    emitter.on(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE,
      resetLoadableButtons.bind(null, TopRowSlotButtons.BUTTON_ONE)
    );
  };

  const removeLoadableButtonsListeners = () => {
    emitter.off(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE,
      resetLoadableButtons.bind(null, TopRowSlotButtons.BUTTON_ONE)
    );
  };

  const resetLoadableButtons = (btn: TopRowSlotButtons) => {
    loadableButtons.value[btn] = false;
  };

  addLoadableButtonsListeners();
  onUnmounted(() => removeLoadableButtonsListeners());
  // ----- Top Row Slot Buttons and Events -----

  return { loadableButtons, clickRowButtonOne };
}
