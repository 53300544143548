<script setup lang="ts" generic="T extends string, U extends string">
import { ref, shallowRef, computed, watch } from "vue";
import SettingsInput from "@/components/ui/SettingsInput.vue";

type OptionInput = {
  mapVal: T;
  stringVal: U;
};

const props = defineProps<{
  inputName: string;
  labelText: string;
  options: OptionInput[];
  selectedOption: T;
  disabled?: boolean;
  autofocusInput: boolean;
  readonly?: boolean;
  errored?: boolean;
  noFilter?: boolean;
  noClear?: boolean;
}>();
const emit = defineEmits<{
  (e: "changeOption", mapVal: T): void; // ! Still need to check for value when using this component as this value can be ""
}>();

const stringValOfMapVal = (val: T) => {
  return props.options.find((option) => option.mapVal === val)!.stringVal!;
};

const selectedFromList = stringValOfMapVal(props.selectedOption);
const textInputVal = shallowRef(selectedFromList); // check issue here https://github.com/vuejs/core/issues/1324#issuecomment-859766527
const showDropdown = ref(false);
const textInputBox = ref<HTMLElement | null>(null);

const filteredList = computed(() => {
  if (props.noFilter) return props.options;
  return props.options.filter((op) => op.stringVal.toLowerCase().includes(textInputVal.value.toLowerCase()));
});

const closeDropdown = (): void => {
  showDropdown.value = false;
};

const openDropdown = (): void => {
  showDropdown.value = true;
};

const actualOptionFromUserDiffCaseInput = (val: U) => {
  return props.options.find((op) => op.stringVal === val);
};

watch(
  textInputVal,
  (newVal) => {
    if (!newVal) return emit("changeOption", "" as T); // unfortunate assertion to fool the TS compiler

    const actualOption = actualOptionFromUserDiffCaseInput(newVal as U); // unfortunate assertion to fool the TS compiler
    if (!actualOption) return emit("changeOption", "" as T); // unfortunate assertion to fool the TS compiler

    textInputVal.value = stringValOfMapVal(actualOption.mapVal);
    closeDropdown();

    emit("changeOption", actualOption.mapVal);
  },
  { immediate: true }
);

const updateTextInput = (val: T) => {
  textInputVal.value = stringValOfMapVal(val);
};

const clearTextInput = (): void => {
  textInputVal.value = "" as U;
};
</script>

<template>
  <div class="dropdown-selector-container">
    <div ref="textInputBox">
      <!-- unfortunate ignore because Generics in Vue are still a bit rough on the edges -->
      <!-- @vue-ignore -->
      <SettingsInput
        :hasFocusEvent="true"
        :inputName="props.inputName"
        :labelText="props.labelText"
        :value="textInputVal"
        @update="updateTextInput"
        :errored="props.errored"
        @onFocus="openDropdown"
        :autofocusOn="autofocusInput"
        :readonly="props.readonly"
      >
        <template v-slot:inputLogo v-if="!noClear">
          <div class="input-logo" @click="clearTextInput">
            <img src="../../assets/icons/cancel.svg" alt="cancel" />
          </div>
        </template>
      </SettingsInput>
    </div>

    <div
      class="list-container"
      v-if="showDropdown && filteredList.length"
      v-click-outside:[textInputBox]="closeDropdown"
    >
      <div
        v-for="el in filteredList"
        :key="el.mapVal"
        class="list-element"
        @click="
          () => {
            updateTextInput(el.mapVal);
            closeDropdown();
          }
        "
      >
        {{ el.stringVal }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/ui.css";

.dropdown-selector-container {
  width:100%;
  position: relative;

  & :deep(input) {
    cursor: pointer;
  }
}
.input-logo {
  cursor: pointer;
  & img{
    width: 100%;
    height: 100%;
  }
}
.list-container {
  background-color: var(--clr-white-1);
  border-radius: 1.5em;
  padding: 1em 0 1em 0;
  position: absolute;
  width: 100%;
  max-height: 15em;
  overflow: auto;
  z-index: 1;
  font-family: "Montserrat";
  border: 1px solid var(--clr-grey-3);
  & .list-element {
    padding: 0.5em 1.5em 0.5em 1.5em;
    cursor: pointer;
  }
  & .list-element:hover {
    background-color: var(--clr-grey-1);
  }
}
@mixin scrollbar-two .list-container, 1em, 5px, var(--clr-blue-1);
</style>
