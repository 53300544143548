<script setup lang="ts">
import { ref, computed, toRef } from "vue";
import { useTextInput } from "@/composables/ui/useTextInput";

const props = defineProps<{
  inputName: string;
  value: string;
  labelText: string;
  errored?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{ (e: "update", code: string): void }>();

const { value, focused, emitUpdate, focusInput } = useTextInput(toRef(props, "value"), emit);

const passwordVisibile = ref(false);

const isPasswordInput = computed<boolean>(() => {
  return props.inputName === "password";
});
const isPasswordVisible = computed<boolean>(() => {
  return passwordVisibile.value && isPasswordInput.value;
});
const inputType = computed<string>(() => {
  if (isPasswordVisible.value) return "text";
  return props.inputName;
});

const passwordInputIconUrl = computed<string>(() => {
  return isPasswordVisible.value
    ? new URL("../../assets/logos/eye-open.svg", import.meta.url).href
    : new URL("../../assets/logos/eye-close.svg", import.meta.url).href;
});

const togglePasswordVisibility = () => {
  passwordVisibile.value = !passwordVisibile.value;
};
</script>

<template>
  <div class="text-input">
    <label :for="inputName" :class="{ 'moved-up': focused, error: errored, disabled: disabled }">
      {{ labelText }}
    </label>
    <input
      :type="inputType"
      :class="{ error: errored }"
      :name="inputName"
      :value="value"
      @input="emitUpdate"
      @focus="focusInput"
      @blur="focusInput"
      :disabled="disabled"
      autocomplete="on"
    />
    <div v-if="isPasswordInput && value" class="password-icon" @click="togglePasswordVisibility">
      <img :src="passwordInputIconUrl" alt="eye-icon" />
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/inputs.css";

@mixin inputBox;

.password-icon {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  right: 0.5em;
  transform: translateY(50%);
  background-color: var(--clr-white-1);
}
.password-icon img {
  width: 100%;
  height: 100%;
}
</style>
