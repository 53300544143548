<script setup lang="ts">
import { isMobileBrowser } from "@/util/functions";
import { ref, onMounted } from "vue";

const props = defineProps<{
  classDown: boolean;
  position: { x: number; y: number };
}>();
const emit = defineEmits<{
  (e: "closePopup"): void;
}>();
const emitClose = () => {
  emit("closePopup");
};

const popupContainer = ref<HTMLDivElement | null>(null);

onMounted(() => {
  if (!isMobileBrowser()) changePopupContainerPosition();
});

const changePopupContainerPosition = () => {
  if (!popupContainer.value) return;

  popupContainer.value.style.top = `${props.position.y}px`;
  popupContainer.value.style.left = `${props.position.x}px`;
  if (props.classDown) return;

  const currentPos = popupContainer.value.getBoundingClientRect();
  popupContainer.value.style.transform = `translateX(calc(var(--popup-width) * -1 + (var(--popup-width) - var(--popup-arrow-left)) / 2)) translateY(calc(-${currentPos.height}px - 1.5em))`;
};
</script>

<template>
  <div :class="{ 'popup-container': true, 'class-down': props.classDown }" ref="popupContainer">
    <div class="close-icon" @click="emitClose">
      <img src="@/assets/icons/close.svg" alt="close" />
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.close-icon {
  display: none;
}
.popup-container {
  --popup-width: 25em;
  --popup-arrow-left: 23.5em;
  --popup-offset-top: 1.5em;
}

.popup-container {
  position: absolute;
  background-color: var(--clr-white-1);
  border: 2px solid var(--clr-black-1);
  border-radius: 0.5em;
  width: var(--popup-width);
  height: fit-content;
  padding: 0.5em;
  z-index: 3;
  transform: translateX(calc(var(--popup-width) * -1 + (var(--popup-width) - var(--popup-arrow-left)) / 2 + 0.25em));
}
.popup-container::before {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: black;
  border-width: 2px 0 0 2px;
  width: 15px;
  height: 15px;
  bottom: -10px;
  left: var(--popup-arrow-left);
  background: inherit;
  transform: rotate(225deg);
}
.popup-container.class-down {
  transform: translateX(calc(var(--popup-width) * -1 + (var(--popup-width) - var(--popup-arrow-left)) / 2 + 0.25em))
    translateY(var(--popup-offset-top));
}
.popup-container.class-down::before {
  top: -10px;
  transform: rotate(45deg);
}

@media (--max-tablet-width) {
  .popup-container {
    --popup-width: 20em;
    --popup-arrow-left: 18.5em;
    --popup-offset-top: 1em;
  }
  .popup-container {
    padding: 0.4em;
  }
  .popup-container::before {
    width: 10px;
    height: 10px;
  }
  .popup-container.class-down::before {
    top: -7px;
  }
}

@media (--max-phone-width) {
  .popup-container {
    position: fixed;
    border: 0;
    left: 0;
    bottom: 0;
    top: unset;
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    box-shadow: 0 -5px 10px var(--clr-grey-3);
    box-sizing: border-box;

    &.class-down {
      transform: none;
    }
  }
  .popup-container::before {
    content: none;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: -2em;
    height: 1em;
    width: 1em;
    right: 0.5em;
    background: white;
    border-radius: 50%;
    padding: 0.5em;
    box-shadow: 0 -5px 10px var(--clr-grey-3);
  }
}
</style>
