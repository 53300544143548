<script setup lang="ts">
import SettingsInput from "@/components/ui/SettingsInput.vue";
import { isMobileBrowser } from "@/util/functions";
// @ts-ignore
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { getAppLocale } from "@/util/translations";

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  value: string;
  errored?: boolean;
}>();
const emit = defineEmits<{ (e: "change", value: string): void }>();

const emitChange = (value: string): void => {
  emit("change", value);
};

const onUpdate = (date: Date): void => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const value = `${date.getFullYear()}-${month}-${day}`;
  emitChange(value);
};

const onTextInput = (value: string): void => {
  emitChange([4, 7].includes(value.length) ? `${value}-` : value);
};

const startDate = new Date();
startDate.setFullYear(new Date().getFullYear() - 18);
</script>

<template>
  <Datepicker
    name="birthday"
    :enable-time-picker="false"
    text-input
    @update:model-value="onUpdate"
    :start-date="startDate"
    week-start="0"
    menu-class-name="dp-custom-menu"
    :flow="['year', 'month', 'calendar']"
    auto-apply
    :offset="0"
    position="left"
    :text-input-options="{ openMenu: !isMobileBrowser() }"
    six-weeks
    :locale="getAppLocale(route.query.lang as any)"
    :style="{ width: '100%' }"
    :format="'yyyy-MM-dd'"
  >
    <template #trigger>
      <SettingsInput
        inputName="birthday"
        :labelText="t('SETTINGS.PROFILE.DATE.YYYY_MM_DD')"
        :value="props.value"
        :errored="errored"
        @update="onTextInput"
        :autofocusOn="false"
      >
        <template v-slot:inputLogo v-if="!isMobileBrowser()">
          <div class="input-logo">
            <img src="@/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </template>
      </SettingsInput>
    </template>
  </Datepicker>
</template>

<style scoped lang="pcss">
;
@import "@/assets/styles/layout.css";

.dp-custom-menu {
  box-shadow: 0 2px 4px -1px var(--clr-black-4), 0 4px 5px var(--clr-black-5), 0 1px 10px var(--clr-black-6);
  border: none;
  border-radius: 1em;

  & .dp__overlay {
    border-radius: 1em;
  }
  & .dp__button, & .dp__arrow_top, & .dp__arrow_bottom{
    display: none;
  }
  & .dp__month_year_select{
    pointer-events: none;
  }
}

.input-logo {
  cursor: pointer;
  position: absolute;
  right: 1em;
  width: 1.5em;
  height: 1.5em;

  & img {
    width: 100%;
    height: 100%;
  }

}
</style>
