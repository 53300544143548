<template>
  <div class="loader-error"></div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/animations.css";

.loader-error {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid var(--clr-credits-high-error);
  width: 20px;
  height: 20px;
  -webkit-animation: loader-spin 2s linear infinite; /* Safari */
  animation: loader-spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
