import { ref, computed } from "vue";
import FaceSad from "@/components/ui/icons/FaceSad.vue";
import FaceBorderline from "@/components/ui/icons/FaceBorderline.vue";
import FaceHappy from "@/components/ui/icons/FaceHappy.vue";
import { useI18n } from "vue-i18n";
import { StatusMarkerType, StatusMarkerClass } from "@/stores/checkup-results";

type IconType = { component: any; props: { fillColor: string }; text: string };
type StatusClassKeys = keyof typeof StatusMarkerClass;

export function useStatusUI(status: StatusMarkerType, coloredIcon: boolean) {
  const { t } = useI18n();
  const cssColor = ref("white");

  const statusToIconType = computed<{ [key in StatusMarkerType]: IconType }>(() => {
    return {
      criticalLow: {
        component: FaceSad,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.CRITICAL_LOW"),
      },
      low: {
        component: FaceSad,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.LOW"),
      },
      lower: {
        component: FaceBorderline,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.LOWER"),
      },
      optimal: {
        component: FaceHappy,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.OPTIMAL"),
      },
      higher: {
        component: FaceBorderline,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.HIGHER"),
      },
      high: {
        component: FaceSad,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.HIGH"),
      },
      criticalHigh: {
        component: FaceSad,
        props: { fillColor: cssColor.value },
        text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.CRITICAL_HIGH"),
      },
    };
  });

  const elementClass = computed<(typeof StatusMarkerClass)[StatusClassKeys]>(() => {
    return StatusMarkerClass[status];
  });

  const iconType = computed<IconType>(() => {
    return statusToIconType.value[status];
  });

  const getCSSColor = () => {
    const style = getComputedStyle(document.body);
    switch (status) {
      case StatusMarkerType.CRITICAL_LOW:
        cssColor.value = style.getPropertyValue("--clr-status-ic-critical-low");
        break;
      case StatusMarkerType.LOW:
        cssColor.value = style.getPropertyValue("--clr-status-ic-low");
        break;
      case StatusMarkerType.LOWER:
        cssColor.value = style.getPropertyValue("--clr-status-ic-lower");
        break;
      case StatusMarkerType.OPTIMAL:
        cssColor.value = style.getPropertyValue("--clr-status-ic-optimal");
        break;
      case StatusMarkerType.HIGHER:
        cssColor.value = style.getPropertyValue("--clr-status-ic-higher");
        break;
      case StatusMarkerType.HIGH:
        cssColor.value = style.getPropertyValue("--clr-status-ic-high");
        break;
      case StatusMarkerType.CRITICAL_HIGH:
        cssColor.value = style.getPropertyValue("--clr-status-ic-critical-high");
        break;
      default:
        break;
    }
  };
  if (coloredIcon) getCSSColor();

  return { iconType, elementClass };
}
