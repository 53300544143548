import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { BUY_UPLOAD_BLOOD_TEST_CREDITS_LINKS } from "@/util/constants";
import { useI18n } from "vue-i18n";
import { useCheckupResultsStore } from "@/stores/checkup-results";

export enum CountStatus {
  HAVE_CREDIT = "HAVE_CREDIT",
  NO_CREDIT = "NO_CREDIT",
  ERROR = "ERROR",
}

export function useBloodTestsCredits() {
  const { t } = useI18n();
  const checkupStore = useCheckupResultsStore();
  const { bloodTestUploadCredits } = storeToRefs(checkupStore);

  const countStatus = ref<CountStatus>(CountStatus.HAVE_CREDIT);

  const updateCountStatus = () => {
    if (bloodTestUploadCredits.value === null) {
      countStatus.value = CountStatus.ERROR;
    } else if (bloodTestUploadCredits.value === 0) {
      countStatus.value = CountStatus.NO_CREDIT;
    } else if (bloodTestUploadCredits.value > 0) {
      countStatus.value = CountStatus.HAVE_CREDIT;
    }
  };

  const countStatusClass = computed<string>(() => {
    switch (countStatus.value) {
      case CountStatus.HAVE_CREDIT:
        return "success";
      case CountStatus.NO_CREDIT:
        return "neutral";
      case CountStatus.ERROR:
        return "error";
      default:
        return "" as never;
    }
  });

  const cardActionText = computed<string>(() => {
    if (countStatus.value === CountStatus.HAVE_CREDIT) return t("SECTION.BLOOD_DROP.UPLOAD.BUY_MORE");
    if (countStatus.value === CountStatus.NO_CREDIT) return t("SECTION.BLOOD_DROP.UPLOAD.BUY_MORE");
    if (countStatus.value === CountStatus.ERROR) return t("SECTION.BLOOD_DROP.UPLOAD.TRY_AGAIN");
    return "" as never;
  });

  const cardInfoText = computed<string>(() => {
    if (bloodTestUploadCredits.value === null) return t("SECTION.BLOOD_DROP.UPLOAD.ERROR_CREDITS");
    return t("SECTION.BLOOD_DROP.UPLOAD.YOU_HAVE_CREDITS", { n: bloodTestUploadCredits.value });
  });

  // ----- BUY CREDITS -----
  const butCreditsLink = computed<string>(() => {
    if (import.meta.env.VITE_NODE_ENV === "production") return BUY_UPLOAD_BLOOD_TEST_CREDITS_LINKS.production;
    else return BUY_UPLOAD_BLOOD_TEST_CREDITS_LINKS.staging;
  });

  const goToBuyCredits = () => window.location.replace(butCreditsLink.value);
  // ----- BUY CREDITS -----

  return {
    cardActionText,
    goToBuyCredits,
    countStatusClass,
    updateCountStatus,
    countStatus,
    bloodTestUploadCredits,
    cardInfoText,
  };
}
