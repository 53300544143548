import { markRaw, createApp } from "vue";
import { createPinia } from "pinia";
import { initializeTranslations } from "@/includes/i18n-setup";
import VClickOutside from "@/directives/click-outside";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { emitter, injectionKey } from "@/includes/emmiter";

import App from "./App.vue";
import router from "./router";

initializeTranslations().then((i18n: any) => {
  const app = createApp(App);
  const pinia = createPinia();

  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });

  app.provide(injectionKey, emitter);

  app.use(pinia);
  app.use(router);
  app.use(i18n);
  app.directive("click-outside", VClickOutside);
  app.component("MazPhoneNumberInput", MazPhoneNumberInput);

  app.mount("#app");
});
