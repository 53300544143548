<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useEventListener } from "@/composables/useEventListener";

const props = defineProps<{
  values: { value: string; transl: string }[];
  selectedValue: string;
}>();

const emit = defineEmits<{
  (e: "toggle-change", selectedValue: string): void;
}>();

const toggleBetweenTwoValues = () => {
  return props.values.find((obj) => obj.value !== props.selectedValue)?.value as string;
};

const handleChange = (val: string, index: number) => {
  let updatedIndex = index;
  let newVal = val;

  if (props.values.length === 2) {
    newVal = toggleBetweenTwoValues();
    updatedIndex = props.values.findIndex((obj) => obj.value === newVal);
  }

  updateSelectedElementCoordinates(updatedIndex);
  emit("toggle-change", newVal);
};

// ----- Animations -----
const itemsContainer = ref<HTMLElement | null>(null);
const itemsContainerCoordinates = reactive({ x: 0 });

const items = ref<HTMLElement[] | null>(null);
const selectedBg = ref<HTMLElement | null>(null);
const selectedElementCoordinates = reactive({ x: 0, y: 0, width: 0, height: 0 });

onMounted(async () => {
  const activeIndex = props.values.findIndex((obj) => obj.value === props.selectedValue);

  // * Don't understand what the hell is happening here. 😱 The itemsContainer DOM element changes its width
  // * immediatelly after onMounted and the initial width is wrong, that's why I wait a random ms time 💩
  // TODO check in the future
  setTimeout(() => {
    updateItemsContainerCoordinates();
    updateSelectedElementCoordinates(activeIndex);
  }, 200);
});

const updateItemsContainerCoordinates = () => {
  const icRect = itemsContainer.value!.getBoundingClientRect();
  itemsContainerCoordinates.x = icRect.x;
};

useEventListener(window, "resize", updateItemsContainerCoordinates);

const updateSelectedElementCoordinates = (index: number) => {
  if (!items.value) return;

  const el = items.value[index];
  const elRect = el.getBoundingClientRect();
  selectedElementCoordinates.x = elRect.x;
  selectedElementCoordinates.y = elRect.y;
  selectedElementCoordinates.width = elRect.width;
  selectedElementCoordinates.height = elRect.height;

  animateSelectedBgMove();
};

const animateSelectedBgMove = () => {
  selectedBg.value!.style.width = `${selectedElementCoordinates.width}px`;
  selectedBg.value!.style.height = `${selectedElementCoordinates.height}px`;
  selectedBg.value!.style.left = `${selectedElementCoordinates.x - itemsContainerCoordinates.x}px`;
};
// ----- Animations -----
</script>

<template>
  <div class="toggler-horizontal-container">
    <div class="item-container" ref="itemsContainer">
      <div
        v-for="(obj, index) in props.values"
        :key="obj.value"
        class="item"
        @click="handleChange(obj.value, index)"
        ref="items"
      >
        <span :class="{ selected: obj.value === props.selectedValue }">{{ obj.transl }}</span>
      </div>
      <div class="selected-bg" ref="selectedBg"></div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.toggler-horizontal-container {
  background-color: var(--clr-grey-1);
  padding: 0.3em;
  border-radius: 2.5em;
}
.item-container {
  display: flex;
  position: relative;
  border-radius: 1.5em;
  background-color: var(--clr-grey-1);
}
.item {
  position: relative;
  font-size: 1.1em;
  flex: 1;
  padding: 0.25em 2em 0.25em 2em;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  color: var(--clr-blue-1);
  font-weight: bold;
}
.item .selected {
  color: var(--clr-black-1);
}
.selected-bg {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 2px -2px var(--clr-grey-4);
  background-color: var(--clr-white-1);
  border-radius: 1.5em;
  z-index: 0;
  transition: all 0.3s ease;
}
</style>
