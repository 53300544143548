<script setup lang="ts"></script>

<template>
  <div class="auth-check-email">
    <div class="email-logo">
      <img src="@/assets/logos/mail-logo.svg" alt="mail-logo" />
    </div>
    <slot name="text"></slot>
    <slot name="button"></slot>
  </div>
</template>

<style scoped>
@import "@/assets/styles/auth.css";

@mixin check-email-logo;
</style>
