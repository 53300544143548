<script setup lang="ts">
import defaultUserAvatar from "@/assets/logos/user-avatar.png";
import { useMemberStore } from "@/stores/member";
import { RouteName } from "@/types/AuthTypes";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const router = useRouter();
const memberStore = useMemberStore();
const { profile } = storeToRefs(memberStore);

const goToProfileInfo = () => {
  router.push({ name: RouteName.SETTINGS_PROFILE });
};
</script>

<template>
  <div class="user-cont">
    <div class="user-name">{{ memberStore.firstName }}</div>
    <div class="user-avatar" @click="goToProfileInfo">
      <img :src="profile!.avatar?.thumbUrl || defaultUserAvatar" alt="avatar" />
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/navbars.css";

.user-cont {
  display: flex;
  align-items: center;
}
.user-cont > div {
  margin: 0.5em;
}
.user-name {
  text-align: right;
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}

.user-avatar {
  overflow: hidden;
  border-radius: 1.3rem;
  cursor: pointer;
}
.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-dots {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.user-dots img {
  width: inherit;
  height: inherit;
}
.infobar .user-avatar {
  flex-shrink: 0;
  width: 3em;
  height: 3em;
  border-radius: 0.8rem;
}
.infobar.user-cont {
  z-index: 2;
}
.infobar .user-dots {
  width: 2em;
  height: 2em;
}
.infobar .user-name {
  max-width: fit-content;
  word-break: normal;
}
.questionnaire .user-name {
  max-width: 12em;
  font-size: 0.9em;
}
.questionnaire .user-avatar {
  width: 3em;
  height: 3em;
  border-radius: 0.8rem;
}
.questionnaire .user-dots {
  width: 2em;
  height: 2em;
}

@media (--max-tablet-width) {
  .user-cont > div {
    margin: 0.2em;
  }
  .infobar .user-avatar {
    width: 2em;
    height: 2em;
    border-radius: 0.5rem;
  }
  .infobar .user-dots {
    width: 1.5em;
    height: 1.5em;
  }
  .infobar .user-name {
    font-size: 0.9em;
  }
  .popup-content {
    font-size: 0.9em;
  }
  .questionnaire .user-cont > div {
    margin: 0.25em;
  }
  .questionnaire .user-name {
    font-size: 0.9em;
  }
  .questionnaire .user-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 0.5rem;
  }
  .questionnaire .user-dots {
    width: 1.5em;
  }
  .questionnaire .user-dots img {
    width: 100%;
  }
}
</style>
