import { ref, computed, onMounted, onBeforeUnmount, toValue } from "vue";
import defaultUserAvatar from "@/assets/logos/user-avatar.png";
import type { MemberProfile_Settings_PUT } from "@/types/settings-page/Profile";

export function useAvatar(thumbUrl: () => string | null, changeCB?: () => void) {
  const avatarChangedPhotoSrc = ref<any>("");
  const avatarForm = ref<HTMLElement | null>(null);
  const avatarFile = ref<any | null>(null);

  onMounted(() => attachAvatarEvents());
  onBeforeUnmount(() => removeAvatarEvents());

  const avatarImgSrc = computed<string>(() => {
    return avatarChangedPhotoSrc.value || toValue(thumbUrl) || defaultUserAvatar;
  });

  const createAvatarPutPayload = async (): Promise<[boolean, null | MemberProfile_Settings_PUT["avatar"]]> => {
    const file = avatarFile.value!.files[0];

    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = () => {
        const newAvatar: MemberProfile_Settings_PUT["avatar"] = { content: "", contentType: "", filename: "" };
        const content = fr.result as string;
        newAvatar.content = content.split("data:image/png;base64,")[1];
        newAvatar.contentType = file.type;
        newAvatar.filename = file.name;
        return resolve([false, newAvatar]);
      };
      fr.onerror = (err) => {
        console.error("Error trying to convert the selected avatar image to base64 encoding, ", err);
        return [true, null];
      };
      fr.readAsDataURL(file);
    });
  };

  const updateAvatarImageOnChange = (e: any): void => {
    const fr = new FileReader();
    fr.onload = () => {
      avatarChangedPhotoSrc.value = fr.result;
      if (changeCB) changeCB();
    };

    fr.readAsDataURL(e.target.files[0]);
  };

  const getNewAvatarPayloadOrNull = async () => {
    let newAvatar = null;
    if (avatarFile.value.files[0]) {
      const [err, avatar] = await createAvatarPutPayload();
      if (err || !avatar) return null;
      newAvatar = avatar;
    }
    return newAvatar;
  };

  const attachAvatarEvents = (): void => {
    if (!avatarForm.value || !avatarFile.value) return;
    avatarFile.value.addEventListener("change", updateAvatarImageOnChange);
  };

  const removeAvatarEvents = (): void => {
    if (!avatarForm.value || !avatarFile.value) return;
    avatarFile.value.removeEventListener("change", updateAvatarImageOnChange);
  };

  return { avatarImgSrc, avatarForm, avatarFile, getNewAvatarPayloadOrNull };
}
