import { useAuthStore } from "@/stores/auth";
import { DownloadMobileAppTextKey, useMainStore } from "@/stores/main";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import { RouteActions } from "@/types/AuthTypes";
import { isMobileBrowser } from "@/util/functions";

const isUserAlreadyLoggedIn = async () => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) return true;

  const successFromLocalStorage = await authStore.login();
  if (successFromLocalStorage) return true;

  return false;
};

const handleDownloadMobileAppPageText = (to: any) => {
  if (
    to.matched.some(
      (record: any) => record.name === RouteName.OPEN_PAGE && to.query.action === RouteActions.UPLOAD_BLOOD_TEST
    )
  ) {
    const mainStore = useMainStore();
    mainStore.changeDownloadMobileAppTextKey(DownloadMobileAppTextKey.UPLOAD_BT);
  }
};

export const globalGuards = async (to: any) => {
  if (isMobileBrowser()) {
    handleDownloadMobileAppPageText(to);
    if (to.matched.some((record: any) => record.meta.emailLink) || !!to.query.action) {
      return true;
    }
    if (to.name === RouteName.DOWNLOAD_MOBILE_APP) return true;
    return { name: RouteName.DOWNLOAD_MOBILE_APP };
  }

  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    const isLoggedIn = await isUserAlreadyLoggedIn();

    if (isLoggedIn) return true;
    if (to.query.token) return true; // Will validate token at a later local guard

    return { name: RouteName.AUTH_EMAIL, query: { ...to.query } };
  }
};

export const signInWithUrlToken = async (to: any) => {
  if (to.query.token) {
    const authStore = useAuthStore();
    const [err, _res]: any[] = await authStore.confirmSignIn(to.query.token);

    if (err) {
      authStore.logout();
      authStore.redirectTo(RouteName.AUTH_ERROR_MAGIC);
      return false;
    } else return true;
  }

  return true;
};

export const redirectIfLoggedIn = async (to: any) => {
  if (to.name === RouteName.AUTH_EMAIL_FP) return true;
  if (to.name === RouteName.AUTH_EMAIL_CHANGE) return true;

  const authStore = useAuthStore();

  if (authStore.isLoggedIn) return { name: RouteNameMenu.MENU_ANALYTICS };

  const success = await authStore.login();
  if (!success) return true;

  return { name: RouteNameMenu.MENU_ANALYTICS };
};

export const authPasswordGuard = () => {
  const authStore = useAuthStore();

  if (authStore.email) return true;
  return { name: RouteName.AUTH_EMAIL };
};

export const authTokenPresentGuard = (to: any) => {
  const authStore = useAuthStore();

  if (to.query.token || authStore.email) return true;
  return { name: RouteName.AUTH_EMAIL };
};

export const preserveRouteQueries = (route: any, query: any) => {
  return { ...route, query: { ...query } };
};

export const productionGuard = () => {
  return import.meta.env.VITE_NODE_ENV === "production";
};
