<script setup lang="ts">
import { ref, computed } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { validateEmail } from "@/util/validations/authValidations";
import ButtonMain from "@/components/ui/ButtonMain.vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/types/AuthTypes";

const { t } = useI18n();
const authStore = useAuthStore();
const route = useRoute();

// ----- Email Business Logic -----
const email = ref(route.meta.forgotPasswordScreen ? authStore.email : "");

const handleEmailUpdate = (value: string) => {
  if (emailWrong.value) emailWrong.value = false;
  email.value = value;
};

const handleNext = async () => {
  memberTriedToLoginAtLeastOnce.value = true;
  if (!isEmailValid.value) return;

  if (route.meta.forgotPasswordScreen) return nextEmailFP();
  if (route.meta.changeEmailScreen) return nextEmailChange();
  return nextEmail();
};

const nextEmail = async () => {
  const [err, passwordMustBeReseted]: any[] = await authStore.fetchCheckEmail(email.value);

  if (err.confirmEmail) return authStore.redirectTo(RouteName.AUTH_EMAIL_CONFIRM);
  if (err.newUser) return authStore.redirectTo(RouteName.AUTH_PASS_NEW);
  if (err) return (emailWrong.value = true);

  if (!passwordMustBeReseted) authStore.redirectTo(RouteName.AUTH_PASSWORD, route.query);
  else nextEmailFP();
};

const nextEmailChange = async () => {
  const [err, success]: any[] = await authStore.putRequestEmailChange(email.value);

  if (success) return authStore.redirectTo(RouteName.AUTH_EMAIL_CONFIRM);
  if (err) return (emailWrong.value = true);
};

const nextEmailFP = async () => {
  const [err, success]: boolean[] = await authStore.postRequestPasswordReset(email.value);
  if (err) emailWrong.value = true;
  if (success) authStore.redirectTo(RouteName.AUTH_EMAIL_SENT);
};
// ----- Email Business Logic -----

// ----- Validations -----
const emailWrong = ref(false);
const memberTriedToLoginAtLeastOnce = ref(false);

const isEmailValid = computed<boolean>(() => {
  return validateEmail(email.value) && !emailWrong.value;
});

const isInputErrored = (val: boolean) => {
  return !val && memberTriedToLoginAtLeastOnce.value;
};
// ----- Validations -----

// ----- Template UI -----
const buttonText = computed<string>(() => {
  if (route.meta.forgotPasswordScreen) return t("LOGIN.CONFIRM");
  if (route.meta.changeEmailScreen) return t("LOGIN.CONFIRM");
  else return t("LOGIN.LOGIN");
});
// ----- Template UI -----
</script>

<template>
  <div class="auth-email">
    <div class="info-text" v-if="route.meta.forgotPasswordScreen">{{ $t("LOGIN.EMAIL_FP") }}</div>
    <div class="info-text" v-if="route.meta.changeEmailScreen">{{ $t("LOGIN.EMAIL_CHANGE") }}</div>
    <form @submit.prevent="handleNext" novalidate>
      <div class="email-input">
        <TextInputBox
          :inputName="'email'"
          :labelText="$t('LOGIN.EMAIL')"
          :value="email"
          @update="handleEmailUpdate"
          :errored="isInputErrored(isEmailValid)"
          :disabled="false"
        />
      </div>
      <div class="next-button">
        <ButtonMain @click="handleNext">
          <template v-slot:btnText>{{ buttonText }}</template>
        </ButtonMain>
      </div>
    </form>
  </div>
</template>

<style scoped>
.info-text {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 1em;
}
.email-input {
  margin-bottom: 2rem;
}
.next-button {
  height: 3em;
}
</style>
