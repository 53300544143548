import isEmail from "validator/es/lib/isEmail";
import type { LoginData } from "@/types/AuthTypes";

export const validatePassword = (value: string): boolean => {
  return value.length > 3;
};

export const validateLoginData = (data: LoginData) => {
  return {
    email: isEmail(data.email),
    password: data.password.length <= 3,
  };
};

export const validateEmail = (value: string): boolean => {
  return isEmail(value);
};
