import { type Question, type QuestionnaireAnswers } from "@/stores/questionnaires";
import { QuestionType } from "@/types/QuestionnaireTypes";
import { ProfileInfoMandatoryKeys } from "@/util/constants";

const randomId = Math.random();
const genderQuestionId = randomId + 4;
export const GenderIds = {
  question: randomId + 5,
  answerMale: randomId + 6,
  answerFemale: randomId + 7,
  answerNon: randomId + 8,
};

export const GenderAnswers: QuestionnaireAnswers[] = [
  {
    id: randomId + 9,
    questionId: genderQuestionId,
    memberId: 0,
    availableAnswerId: GenderIds.answerMale,
    freeAnswerValue: "",
  },
  {
    id: randomId + 10,
    questionId: genderQuestionId,
    memberId: 0,
    availableAnswerId: GenderIds.answerFemale,
    freeAnswerValue: "",
  },
  {
    id: randomId + 11,
    questionId: genderQuestionId,
    memberId: 0,
    availableAnswerId: GenderIds.answerNon,
    freeAnswerValue: "",
  },
];

export const ProfileMandatoryQuestions: { [key in ProfileInfoMandatoryKeys]: Question } = {
  avatar: {
    hasMultipleOptions: false,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 1,
    key: ProfileInfoMandatoryKeys.AVATAR,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.NAME.AVATAR",
    questionType: QuestionType.AVATAR,
    mandatory: true,
    availableAnswers: [],
  },
  firstName: {
    hasMultipleOptions: false,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 2,
    key: ProfileInfoMandatoryKeys.FIRST_NAME,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.NAME.FIRST_NAME",
    questionType: QuestionType.TEXT_INPUT,
    mandatory: true,
    availableAnswers: [],
  },
  lastName: {
    hasMultipleOptions: false,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 3,
    key: ProfileInfoMandatoryKeys.LAST_NAME,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.NAME.LAST_NAME",
    questionType: QuestionType.TEXT_INPUT,
    mandatory: true,
    availableAnswers: [],
  },
  gender: {
    hasMultipleOptions: false,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: genderQuestionId,
    key: ProfileInfoMandatoryKeys.GENDER,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.GENDER.QUESTION",
    questionType: QuestionType.PREDEFINED_ANSWERS,
    mandatory: true,
    availableAnswers: [
      {
        id: GenderIds.answerMale,
        negativeAnswer: false,
        predefinedValue: "QUESTIONNAIRES.PROFILE.GENDER.MALE",
        questionId: genderQuestionId,
        value: null,
      },
      {
        id: GenderIds.answerFemale,
        negativeAnswer: false,
        predefinedValue: "QUESTIONNAIRES.PROFILE.GENDER.FEMALE",
        questionId: genderQuestionId,
        value: null,
      },
      {
        id: GenderIds.answerNon,
        negativeAnswer: false,
        predefinedValue: "QUESTIONNAIRES.PROFILE.GENDER.NON",
        questionId: genderQuestionId,
        value: null,
      },
    ],
  },
  phoneNumber: {
    hasMultipleOptions: true,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 12,
    key: ProfileInfoMandatoryKeys.PH_NUMBER,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.PH_NR.QUESTION",
    questionType: QuestionType.PHONE_NUMBER,
    mandatory: true,
    availableAnswers: [],
  },
  birthday: {
    hasMultipleOptions: true,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 13,
    key: ProfileInfoMandatoryKeys.BIRTHDAY,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.BIRTHDAY.QUESTION",
    questionType: QuestionType.BIRTHDAY,
    mandatory: true,
    availableAnswers: [],
  },
  height: {
    hasMultipleOptions: true,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 14,
    key: ProfileInfoMandatoryKeys.HEIGHT,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.HEIGHT.QUESTION",
    questionType: QuestionType.HEIGHT,
    mandatory: true,
    availableAnswers: [],
  },
  weight: {
    hasMultipleOptions: true,
    hasNoneOfTheAboveOption: false,
    hasOtherOption: "",
    id: randomId + 15,
    key: ProfileInfoMandatoryKeys.WEIGHT,
    maxNumberOfOptions: 1,
    minNumberOfOptions: 1,
    questionBody: "QUESTIONNAIRES.PROFILE.WEIGHT.QUESTION",
    questionType: QuestionType.WEIGHT,
    mandatory: true,
    availableAnswers: [],
  },
};
