<script setup lang="ts">
import { useMainStore } from "@/stores/main";

const mainStore = useMainStore();

enum BioniqAppStoreUrl {
  APPLE = "https://apps.apple.com/app/bioniq/id1558100209",
  GOOGLE = "https://play.google.com/store/apps/details?id=com.bioniq.health",
}

const goToStore = (url: BioniqAppStoreUrl) => {
  return window.location.replace(url);
};
</script>

<template>
  <div class="download-mobile-container">
    <div class="img-container">
      <img src="@/assets/logos/bioniq-logo-white.svg" alt="bioniq-logo" />
    </div>
    <div class="texts">
      <div class="border-top"></div>
      <div class="title">{{ $t(`DOWNLOAD_MOBILE.TITLE.${mainStore.downloadMobileAppTextKey}`) }}</div>
      <div class="text">
        <span class="line">{{ $t(`DOWNLOAD_MOBILE.TEXT.${mainStore.downloadMobileAppTextKey}`) }}</span>
      </div>
    </div>
    <div class="stores">
      <div class="apple-store" @click="() => goToStore(BioniqAppStoreUrl.APPLE)">
        <img src="@/assets/img/apple-store.svg" alt="apple-store" />
      </div>
      <div class="google-store" @click="() => goToStore(BioniqAppStoreUrl.GOOGLE)">
        <img src="@/assets/img/google-store.svg" alt="google-store" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/base.css";
.download-mobile-container {
  width: 100vw;
  height: 100vh;
  background-color: var(--clr-blue-4);
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stores {
  display: flex;
  gap: 3em;
  margin-top: 2em;
  & > div {
    width: 8em;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
.img-container {
  width: 40rem;
  height: auto;
  margin: 0 auto;
}
.img-container img {
  width: 100%;
  height: 100%;
}
.texts {
  color: white;
  margin: 0 auto;
  text-align: center;
  padding-top: 2em;
  position: relative;
  max-width: 70em;
}
.texts .border-top {
  position: absolute;
  left: 20%;
  right: 20%;
  border-top: 5px solid white;
}
.texts .title {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  margin-bottom: 0.5em;
  margin-top: 2em;
}
.texts .text {
  font-size: 1rem;
}

@media (--max-tablet-width) {
  .stores {
    gap: 2em;
  }
  .img-container {
    width: 25rem;
  }
  .texts {
    max-width: 50em;
  }
  .texts .title {
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .texts .text {
    font-size: 0.9rem;
  }
}

@media (--max-phone-width) {
  .img-container {
    width: 12rem;
  }
  .texts {
    max-width: 90vw;
  }
  .stores {
    gap: 2em;
    & > div {
      width: 7em;
    }
  }
  .texts .title {
    font-size: 1.2rem;
  }
  .texts .text {
    font-size: 0.7rem;
  }
}
</style>
