<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/types/AuthTypes";
import { useAuthStore } from "@/stores/auth";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const pathName = ref<RouteName>(RouteName.AUTH_EMAIL);

const routesWithoutBackNavigation = [
  RouteName.AUTH_EMAIL,
  RouteName.AUTH_EMAIL_CONFIRMING,
  RouteName.AUTH_SIGNING_IN,
  RouteName.AUTH_SUCCESS_CONFIRM,
];

watch(
  () => route.name as RouteName,
  (path: RouteName) => {
    pathName.value = path;
  },
  { immediate: true }
);

const hasBackNavigation = computed<boolean>(() => {
  const check1 = routesWithoutBackNavigation.includes(pathName.value);
  const check2 = pathName.value === RouteName.AUTH_PASS_NEW && !authStore.email;
  const check3 = pathName.value === RouteName.AUTH_ERROR_CONFIRM && !authStore.email;
  const check4 = pathName.value === RouteName.AUTH_ERROR_MAGIC && !authStore.email;
  return !(check1 || check2 || check3 || check4);
});

const bioniqMoto = computed<string>(() => {
  return t("LOGIN.BIONIQ_MOTO").toUpperCase();
});

const currRouteName = computed(() => {
  return router.currentRoute.value.name;
});

const navigateBack = () => {
  if (currRouteName.value === RouteName.AUTH_EMAIL) return;
  router.back();
};
</script>

<template>
  <!-- Header -->
  <div class="auth-layout">
    <section class="side-left-image"></section>
    <section class="side-right-form">
      <div class="center-area">
        <div class="bioniq-container">
          <div v-if="hasBackNavigation" class="back-arrow" @click="navigateBack">
            <img src="@/assets/icons/black_arrow.svg" alt="back arrow" />
          </div>
          <div class="bioniq-logo">
            <img src="@/assets/logos/bioniq-logo.svg" alt="bioniq logo" />
          </div>
          <div class="bioniq-moto">
            <span>{{ bioniqMoto }}</span>
          </div>
        </div>

        <div class="form-container">
          <slot></slot>
        </div>
      </div>
    </section>
  </div>
  <!-- Footer -->
</template>

<style scoped>
@import "@/assets/base.css";
.auth-layout {
  height: 100vh;
  width: 100vw;
  background-color: var(--clr-white-1);
  color: var(--clr-grey-7);
  display: flex;
}
.side-left-image {
  width: 50%;
  background-image: url("@/assets/img/bioniq-pills.png");
  background-size: cover;
  background-position: center;
}
.side-right-form {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
}
.center-area {
  width: 40%;
  margin: 0 auto;
}
.bioniq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  position: relative;
}
.back-arrow {
  height: 0.9em;
  position: absolute;
  cursor: pointer;
  transform: rotate(90deg);
  left: 0;
  top: 1.5em;
}
.back-arrow img {
  width: 100%;
  height: 100%;
}
.bioniq-logo {
  margin: 1em;
}
.bioniq-moto {
  font-family: Montserrat;
  max-width: 50%;
  text-align: center;
}

@media (--max-laptop-width) {
  .center-area {
    width: 60%;
  }
}

@media (--max-phone-width) {
  .side-left-image {
    display: none;
  }
  .side-right-form {
    width: 100%;
  }
  .bioniq-moto {
    font-size: 0.8em;
  }
  .back-arrow {
    height: 0.7em;
  }
}
</style>
