import { defineStore } from "pinia";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { API } from "@/util/api/api_paths";
import { axiosInstance, setAuthToken } from "@/util/api/axios_instance";
import { useMemberStore } from "@/stores/member";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    email: "",
    isLoggedIn: false,
    emailChange: "",
  }),
  getters: {},
  actions: {
    setAuthTokenInLocalStorage(token: string) {
      localStorage.setItem("Members-Auth-Token", token);
    },
    async fetchCheckEmail(email: string) {
      this.email = email;
      try {
        const res = await axiosInstance.get(API.CHECK_EMAIL, { params: { email } });
        const data = camelcaseKeys(res.data);

        return [false, data.passwordMustBeReset];
      } catch (err: any) {
        console.error("Error on /check_email API, ", err);

        if (err.response.status === 409) return [{ confirmEmail: true }, false];
        if (err.response.status === 404) {
          return [{ newUser: true }, false];
        }
        return [true, false];
      }
    },
    async postSignIn(password?: string) {
      const params: { email: string; password?: string } = { email: this.email };
      if (password) params.password = password;
      try {
        const res = await axiosInstance.post(API.SIGN_IN, params);
        const data = camelcaseKeys(res.data);

        if (!data.session.confirmed) return [false, true]; // * Magic Link flow

        this.setAuthTokenInLocalStorage(data.session.token);
        const success = await this.login();
        if (!success) throw true;

        return [false, true];
      } catch (err) {
        console.error("Error on /sign_in API, ", err);
        localStorage.removeItem("Members-Auth-Token");
        return [true, false];
      }
    },
    async confirmSignIn(token: string) {
      const payload = snakecaseKeys({ confirmationToken: token });
      try {
        const res = await axiosInstance.post(API.SIGN_IN_CONFIRM, payload);
        const data = camelcaseKeys(res.data);

        if (!data.session.confirmed) return [true, false];

        this.setAuthTokenInLocalStorage(data.session.token);
        const success = await this.login();
        if (!success) throw true;

        return [false, true];
      } catch (err) {
        console.error("Error on /sign_in/confirm API, ", err);
        return [true, false];
      }
    },
    async postRequestPasswordReset(email: string) {
      try {
        await axiosInstance.post(API.REQ_RESET, { email });

        this.email = email;

        return [false, true];
      } catch (err) {
        console.error("Error on /request_reset API, ", err);
        return [true, false];
      }
    },
    async postConfirmPasswordRequest({ password, token }: { password: string; token: string }) {
      const payload = snakecaseKeys({ confirmationToken: token, newPassword: password });
      try {
        const res = await axiosInstance.post(API.CONFIRM_RESET, payload);
        const data = camelcaseKeys(res.data);

        this.setAuthTokenInLocalStorage(data.session.token);
        const success = await this.login();
        if (!success) throw true;

        return [false, true];
      } catch (err) {
        console.error("Error on /confirm_reset API, ", err);
        localStorage.removeItem("Members-Auth-Token");
        return [true, false];
      }
    },
    async confirmSignUpEmail(token: string) {
      const payload = snakecaseKeys({ confirmationToken: token });
      try {
        const res = await axiosInstance.post(API.SIGN_UP_CONFIRM, payload);
        const data = camelcaseKeys(res.data);
        return [false, { passwordMustBeReset: data.passwordMustBeReset, resetToken: data.passwordResetToken }];
      } catch (err) {
        console.error("Error on /sign_up/confirm API, ", err);
        return [true, false];
      }
    },
    async postUserSignUp(password: string) {
      const payload = { email: this.email, password };
      try {
        const res = await axiosInstance.post(API.SIGN_UP, payload);
        const data = camelcaseKeys(res.data);
        return [false, data];
      } catch (err) {
        console.error("Error on /sign_up API, ", err);
        return [true, false];
      }
    },
    async putRequestEmailChange(email: string) {
      this.emailChange = email;
      try {
        const res = await axiosInstance.put(API.REQ_EMAIL_RESET, { email });
        const data = camelcaseKeys(res.data);
        return [false, data];
      } catch (err) {
        console.error("Error on request email change API, ", err);
        return [true, false];
      }
    },
    async confirmEmailChange(token: string) {
      this.removeToken();
      const payload = snakecaseKeys({ confirmationToken: token });
      try {
        await axiosInstance.post(API.CONFIRM_EMAIL_CHANGE, payload);
        return [false, true];
      } catch (err) {
        console.error("Error on /email/confirm API, ", err);
        return [true, false];
      }
    },
    async login() {
      const token = localStorage.getItem("Members-Auth-Token");
      if (!token) return false;

      setAuthToken(token);

      const memberStore = useMemberStore();
      const error = await memberStore.fetchMemberProfile();
      if (error) {
        localStorage.removeItem("Members-Auth-Token");
        this.isLoggedIn = false;
        return false;
      }

      this.isLoggedIn = true;
      return true;
    },
    removeToken() {
      localStorage.removeItem("Members-Auth-Token");
    },
    logout() {
      this.removeToken();
    },
    redirectTo(name: string, query?: any) {
      this.router.push({ name, query: { ...query } });
    },
    resetData() {
      this.$reset();
    },
  },
});
