import { ref, type Ref, watch } from "vue";
import { useRoute } from "vue-router";
import { RouteQuery } from "@/types/RouteTypes";
import type { Formulas, SupplementFormulaShort, FormulasComplete } from "@/stores/formulas";
import type { BloodTests, BloodTestShort, BloodTestResults } from "@/stores/checkup-results";

type AcceaptableParam = Formulas | BloodTests;
type StorePayload = SupplementFormulaShort | BloodTestShort;
type StoreResults = FormulasComplete | BloodTestResults;

export function useToggleableListItemMain<T extends AcceaptableParam, U extends StoreResults>(
  storeItemsAllData: Ref<T>,
  storeItemsResults: Ref<U>
) {
  const listItemsHandler = ref<{ [key in number]: { shouldMakeApiCall: boolean } }>({});

  const listItemLayoutToggled = ({ elementId, val }: { elementId: number; val: boolean }) => {
    if (!val) return;
    if (storeItemsResults.value[elementId]) return;

    listItemsHandler.value[elementId].shouldMakeApiCall = true;
  };

  const initializeStoreDataChange = (payload: StorePayload[]): void => {
    payload.forEach((result) => {
      listItemsHandler.value[result.id] = { shouldMakeApiCall: false };
    });
  };

  watch(
    storeItemsAllData.value, // * This is triggered only when there is a different object in memory
    (newData) => {
      if (!newData.totalCount) return;

      if ((newData as BloodTests).results) initializeStoreDataChange((newData as BloodTests).results);
      if ((newData as Formulas).supplementFormulas) initializeStoreDataChange((newData as Formulas).supplementFormulas);
    },
    { immediate: true }
  );

  // ----- check for route queries -----
  const route = useRoute();

  const checkForRouteQueries = () => {
    const elementId = route.query[RouteQuery.ELEMENT_ID] as string;
    if (!elementId || !listItemsHandler.value[parseInt(elementId)]) return;

    listItemsHandler.value[parseInt(elementId)].shouldMakeApiCall = true;
  };

  watch(listItemsHandler.value, checkForRouteQueries);
  // ----- check for route queries -----

  return { listItemLayoutToggled, listItemsHandler };
}
