<template>
  <div class="auth-info">
    <slot name="text"></slot>
    <div class="next-button">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/styles/auth.css";
@mixin info-btn;
</style>
