<script setup lang="ts">
import { useMessagesStore } from "@/stores/messages";
import { formatDate } from "@/util/functions";
import { ref, onMounted, onUnmounted } from "vue";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const messagesStore = useMessagesStore();
const selectedMessageId = ref<number | null>(null);
const isLoading = ref(false);
const loaderRef = ref(null);

const markMessageAsRead = async (event: Event, id: number) => {
  selectedMessageId.value = id;
  event.stopPropagation();
  await messagesStore.markMessageAsRead(id);
  messagesStore.messages = messagesStore.messages.filter((msg) => msg.id !== id);
  messagesStore.totalCount = messagesStore.totalCount - 1;
};

const markAllAsRead = (event: Event) => {
  messagesStore.markAllMessagesAsRead();
  messagesStore.messages = [];
  messagesStore.totalCount = 0;
  event.stopPropagation();
};

const loadNextPage = () => {
  if (isLoading.value || messagesStore.messages.length >= messagesStore.totalCount) return;
  isLoading.value = true;
  messagesStore.currentPage = messagesStore.currentPage + 1;
  messagesStore.fetchMessages().finally(() => {
    isLoading.value = false;
  });
};

// IntersectionObserver callback to load more data when reaching the bottom of the list
const scrollObserver = new IntersectionObserver((entries) => {
  if (entries[0].isIntersecting) {
    loadNextPage();
  }
});

onMounted(() => {
  if (loaderRef.value) scrollObserver.observe(loaderRef.value);
});

onUnmounted(() => {
  if (loaderRef.value) scrollObserver.unobserve(loaderRef.value);
});
</script>

<template>
  <div class="header">
    <div v-if="messagesStore.totalCount">{{ $t("MESSAGES.UNREAD_MESSAGES", { n: messagesStore.totalCount }) }}</div>
    <div v-else class="up-to-date">{{ $t("MESSAGES.UP_TO_DATE") }}</div>
    <div v-if="messagesStore.totalCount" class="mark-all" @click="(event) => markAllAsRead(event)">
      {{ $t("MESSAGES.MARK_ALL_READ") }}
    </div>
  </div>
  <div class="message-list" v-if="messagesStore.totalCount">
    <div class="message" v-for="msg in messagesStore.messages" :key="msg.id">
      <img v-if="msg.message.backgroundPicture" class="picture" :src="messagesStore.getMessagePicture(msg)" />
      <img v-else class="default-picture" src="@/assets/icons/notification.svg" alt="notification" />
      <div class="message-content">
        <div class="title">{{ messagesStore.getMessageTitle(msg) }}</div>
        <div class="body">{{ messagesStore.getMessageBody(msg) }}</div>
        <div class="date">{{ formatDate(msg.sentAt, t) }}</div>
      </div>
      <div class="mark-read" @click="(ev) => markMessageAsRead(ev, msg.id)">
        <img
          class="checkbox"
          v-if="selectedMessageId === msg.id"
          src="@/assets/icons/checkbox-filled.webp"
          alt="checkbox-filled"
        />
        <img class="checkbox" v-else src="@/assets/icons/checkbox-empty.webp" alt="checkbox-empty" />
      </div>
    </div>
    <div class="loader-container" ref="loaderRef">
      <LoaderSpinner v-if="isLoading" />
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/styles/ui.css";

.header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;

  .mark-all {
    color: var(--clr-grey-9);
    cursor: pointer;
  }

  .up-to-date {
    width: 100%;
    text-align: center;
  }
}

@mixin scrollbar-one .message-list;

.message-list {
  padding: 0 0.5em;
  max-height: 85vh;
  overflow-y: scroll;
  margin-top: 0.5em;

  .loader-container {
    min-height: 1.7em;
    .loader-spinner {
      position: relative;
      border-width: 5px;
      border-top-width: 5px;
      width: 1em;
      height: 1em;
    }
  }

  .message {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 0.5em 0;

    &:not(:first-child) {
      border-top: 0.5px solid var(--clr-grey-3);
    }

    .picture {
      width: 2em;
      height: 2em;
      border-radius: 8px;
    }
    .message-content {
      margin: 0 0.3em 0 0.5em;
      .title {
        font-size: 1rem;
      }
      .body {
        font-size: 0.9rem;
      }
      .date {
        font-size: 0.75rem;
        color: var(--clr-grey-9);
      }
    }
    .mark-read {
      .checkbox {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}
@media (--max-phone-width) {
  .up-to-date {
    padding: 2em;
  }
}
</style>
