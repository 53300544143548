<script setup lang="ts">
import { ref, toRef } from "vue";
import { useTextInput } from "@/composables/ui/useTextInput";
import { isMobileBrowser } from "@/util/functions";

const props = defineProps<{
  inputName: string;
  value: string | number;
  labelText: string;
  errored?: boolean;
  disabled?: boolean;
  hasFocusEvent?: boolean;
  autofocusOn?: boolean;
  scrollOnFocus?: boolean;
  readonly?: boolean;
  maxlength?: number;
}>();
const emit = defineEmits<{
  (e: "update", code: string): void;
  (e: "enter"): void;
  (e: "onBlur"): void;
  (e: "onFocus"): void;
}>();

const textInput = ref(null);
const { value, focused, emitUpdate, focusInput } = useTextInput(toRef(props, "value"), emit);

defineExpose({ textInput, focused });

const onFocusInput = (e: Event) => {
  focusInput(e);
  if (props.hasFocusEvent) emit("onFocus");
  if (props.scrollOnFocus && isMobileBrowser()) scrollInputToTop();
};

const inputRef = ref<HTMLInputElement | null>(null);

const scrollInputToTop = (): void => {
  const elementPosition = inputRef.value!.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - 15;

  scrollTo({ top: offsetPosition, behavior: "smooth" });
};
</script>

<template>
  <div class="text-input" ref="inputRef">
    <label :for="inputName" :class="{ 'moved-up': focused, disabled: disabled }">
      {{ labelText }}
    </label>
    <input
      :type="'text'"
      :class="{ error: errored }"
      :name="inputName"
      :value="value"
      @keyup.enter="emit('enter')"
      @input="emitUpdate"
      @focus="onFocusInput"
      @blur="focusInput"
      :disabled="disabled"
      autocomplete="off"
      :autofocus="autofocusOn"
      spellcheck="false"
      ref="textInput"
      :readonly="props.readonly"
      :maxlength="props.maxlength"
    />
    <slot name="inputLogo"></slot>
    <slot name="apply-btn"></slot>
    <slot name="countryCode"></slot>
  </div>
</template>

<style lang="pcss" scoped>
@import "@/assets/styles/settings.css";

@mixin inputBox;

:slotted(.input-logo) {
  position: absolute;
  right: 1em;
  width: 1.5em;
  height: 1.5em;
  & img {
    width: 100%;
    height: 100%;
  }
}
:slotted(.country-code) {
  width: 1.5em;
  height: 1.5em;

  & img {
    width: 100%;
    height: 100%;
  }
}
.marginLeft{
  margin-left:5em;
}
.text-input{
  width: 100%;
}
</style>
