import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useFormulasStore } from "@/stores/formulas";
import { useMemberStore } from "@/stores/member";
import { emitter, EmmitEvents } from "@/includes/emmiter";

export function useFormula() {
  const { t } = useI18n();
  const formulaStore = useFormulasStore();
  const memberStore = useMemberStore();

  const { formulas, formulasComplete } = storeToRefs(formulaStore);

  const formulaTitleByIndex = (index: number): string => {
    return `${t("SECTION.FORMULAS.FORMULA.TITLE", {
      productType: formulas.value.supplementFormulas[index].productType.toUpperCase(),
    })} Nº${index + 1}`;
  };

  const formulaTitleByCustomerName = computed<string>(() => {
    return `${memberStore.fullName} ${t("SECTION.FORMULAS.FORMULA.FORMULA_RECIPE")}`;
  });

  const exportFormulaToPDF = async (formulaId: number): Promise<void> => {
    const url = formulasComplete.value[formulaId].recipePdf;

    try {
      const res = await fetch(url);
      const b = await res.blob();
      const a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", formulaTitleByCustomerName.value);
      a.click();
    } catch (err) {
      console.error(`Error trying to download the recipe pdf, ${err}`);
    }

    emitter.emit(EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE);
  };

  return { formulaTitleByIndex, exportFormulaToPDF };
}
