<script setup lang="ts">
import { computed } from "vue";
import MainLayout from "@/components/layout/MainLayout.vue";
import { isMobileBrowser } from "@/util/functions";
import { useRoute } from "vue-router";
import MagicLinkMobile from "@/components/layout/MagicLinkMobile.vue";

const route = useRoute();

const isMobile = computed<boolean>(() => {
  return isMobileBrowser();
});

const isActionable = computed<boolean>(() => {
  return !!route.query.action;
});
</script>

<template>
  <MagicLinkMobile v-if="isMobile && isActionable" />

  <MainLayout v-else>
    <RouterView />
  </MainLayout>
</template>

<style scoped></style>
