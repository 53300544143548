import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { QuestionnairesKey, mandatoryQuestionnaireKeys } from "@/stores/questionnaires";
import { LOCALE, Currency } from "@/util/constants";
import type {
  AvatarPut,
  MemberProfile_Settings_PUT,
  ProfileInfoQuestionnaire_PUT,
} from "@/types/settings-page/Profile";
import {
  transformInchesToCm,
  transformInchesToFeetInches,
  transformCmToFeetInches,
  transformFeetInchesToInches,
} from "@/util/functions";
import { ProfileInfoMandatoryKeys } from "@/util/constants";

// TODO remove from here
export type PhoneNumberData = {
  value: string;
  countryCode: string;
  isValid: boolean;
  e164: string;
};
export interface UserInfoType {
  account: MemberAccount;
  phoneNumber: PhoneNumberData;
  avatar: { thumbUrl: string } | null;
  birthday: string;
  height: string;
  weight: string;
  contentLocale: string;
}

// TODO remove from here

export interface MemberAccount {
  email: string;
  firstName: string;
  lastName: string;
  gender: Gender;
}
export interface MemberAvatar {
  originalUrl: string;
  thumbUrl: string;
}

// TODO move this types somewhere else -----
export interface CallPackage {
  id: number;
  purchasedCalls: number;
  usedCalls: number;
  callsLeft: number;
  expirationDate: string;
  active: boolean;
}
export interface CustomGoal {
  id: number;
  name: string;
}
export interface Goal extends CustomGoal {
  description: string;
}
export type MemberType = "customer";
export type NpsQuestionCategory = "product";

export type PreferredWorkoutDay = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
export type MemberVisibility = "publicly_available";

export interface SubscriptionType {
  currency: Currency;
  description: string;
  includedCalls: number;
  includedGroupCalls: number;
  key: string;
  name: string;
  price: number;
  provider: "charge_bee";
}

export type MemberSubscriptionState = "ACTIVE";
export interface MemberSubscription {
  callsLeft: number;
  cancellationScheduledAt: string | null;
  id: number;
  pausable: boolean;
  pauseEndTime: string | null;
  pauseStartTime: string | null;
  paused: boolean;
  paymentPageUrl: string | null;
  periodResetDate: string;
  provider: "charge_bee";
  scheduledChangeDate: string | null;
  scheduledChangedType: number | null;
  state: MemberSubscriptionState;
  subscriptionType: SubscriptionType;
  usedCalls: number;
  usedGroupCalls: number;
}
// TODO move this types somewhere else -----
export enum Gender {
  MALE = "male",
  FEMALE = "female",
  CRAZY = "", // ! This will actually be null from BE
}

export enum WeightUnit {
  POUNDS = "lbs",
  KG = "kg",
}
export enum HeightUnit {
  INCHES = "inches",
  CM = "cm",
}
export enum HeightImperialUnit {
  FEET = "feet",
  INCHES = "inches",
}

export interface MemberProfile {
  acceptedTermsAndConditionsKeys: (string | null)[];
  account: MemberAccount;
  areasOfInterest: string[]; // TODO revisit type
  availableLanguages: LOCALE[];
  avatar: MemberAvatar | null;
  birthday: string;
  bloodTestUploadCredits: number;
  bodyMassIndex: number;
  callPackages: CallPackage[];
  contentLocale: LOCALE;
  countryCode: string;
  customGoals: CustomGoal[];
  equipmentIds: number[];
  fitnessLevelId: number;
  freeTrialPeriod: boolean;
  freeTrialPeriodEndsAt: string;
  goals: Goal[];
  heightUnit: HeightUnit;
  height: number;
  id: number;
  lastUsedAt: string | null;
  locale: LOCALE;
  longestStreak: number;
  medicalCondition: boolean;
  memberType: MemberType;
  mobileSubscription: {
    active: boolean;
    expiresAt: string;
    trialPeriodEndsAt: string;
    trialPeriodAvailable: boolean;
  };
  nonSubscriberRights: boolean;
  npsQuestionCategory: NpsQuestionCategory;
  pauseMonthsLeft: number;
  phoneNumber: string;
  preferredWorkoutDays: PreferredWorkoutDay[];
  questionnaires: { key: QuestionnairesKey; completed: boolean }[]; // TODO the key: QuestionnaireKey will have to change to just string, as we get the mandatory questionnaire from API
  refrerralCode: string;
  showNpsQuestion: boolean;
  subscription: MemberSubscription;
  subscriptionActive: boolean;
  timezone: string;
  totalAvailableCalls: number;
  trainerGender: Gender | null;
  trainersEmails: string[];
  trainingPlanDisabled: boolean;
  visibility: MemberVisibility;
  weight: number;
  weightUnit: WeightUnit;
}

export const useMemberStore = defineStore({
  id: "member",
  state: () => ({
    profile: null as MemberProfile | null,
  }),
  getters: {
    firstName(): string {
      if (!this.profile) return "";
      return this.profile.account.firstName;
    },
    fullName(): string {
      if (!this.profile) return "";
      return `${this.profile.account.firstName} ${this.profile.account.lastName}`;
    },
    getHeightInCm(): string {
      if (!this.profile?.height) return "";
      if (this.profile.heightUnit === HeightUnit.CM) {
        return this.profile.height.toString();
      } else if (this.profile.heightUnit === HeightUnit.INCHES) {
        return transformInchesToCm(this.profile.height.toString());
      } else {
        return "" as never;
      }
    },
    getHeightInFeetInches(): { [key in HeightImperialUnit]: string } {
      if (!this.profile?.height) return { feet: "", inches: "" };

      if (this.profile.heightUnit === HeightUnit.CM) {
        return transformCmToFeetInches(this.profile.height.toString());
      } else if (this.profile.heightUnit === HeightUnit.INCHES) {
        return transformInchesToFeetInches(this.profile.height.toString());
      } else {
        return "" as never;
      }
    },
    mandatoryUncompletedQuestionnaires(): QuestionnairesKey[] {
      if (!this.profile || !this.profile.questionnaires) return [];

      const uncompleted = this.profile.questionnaires
        .map((quest: { key: QuestionnairesKey; completed: boolean }) => {
          if (mandatoryQuestionnaireKeys.includes(quest.key)) {
            if (!quest.completed) return quest.key;
          }
        })
        .filter((el) => !!el) as QuestionnairesKey[];

      return uncompleted;
    },
    bloodTestUploadCredits(): number {
      if (!this.profile) return 0 as never;
      return this.profile.bloodTestUploadCredits;
    },
    mandatoryProfileInfo(): { [key in ProfileInfoMandatoryKeys]: any } {
      return {
        firstName: this.profile ? this.profile.account.firstName : "",
        lastName: this.profile ? this.profile.account.lastName : "",
        gender: this.profile ? this.profile.account.gender : null,
        phoneNumber: this.profile ? this.profile.phoneNumber : null,
        birthday: this.profile ? this.profile.birthday : null,
        height: this.profile ? this.profile.height : null,
        weight: this.profile ? this.profile.weight : null,
        avatar: this.profile ? this.profile.avatar : null,
      };
    },
    isMandatoryProfileInfoCompleted(): boolean {
      if (!this.profile) return false as never;

      return Object.values(ProfileInfoMandatoryKeys)
        .map((value) => {
          if (value === ProfileInfoMandatoryKeys.GENDER) return true;
          if (value === ProfileInfoMandatoryKeys.FIRST_NAME || value === ProfileInfoMandatoryKeys.LAST_NAME)
            return !!this.profile!.account[value];

          return !!this.profile![value];
        })
        .every((val) => !!val);
    },
  },
  actions: {
    async fetchMemberProfile() {
      try {
        const res = await axiosInstance.get(API.MEMBER_PROFILE);
        this.profile = camelcaseKeys(res.data.profile, { deep: true });

        return false;
      } catch (err) {
        console.error("Error on GET /profile API, ", err);
        return true;
      }
    },
    async updateMemberProfile(
      userInformation: MemberProfile_Settings_PUT
    ): Promise<[null | Error, null | MemberProfile]> {
      const payload = snakecaseKeys({ profile: userInformation });
      try {
        // @ts-ignore
        if (!payload.profile.account.gender) payload.profile.account.gender = null; // * Handle Gender Non Binary as null

        const res = await axiosInstance.put(API.MEMBER_PROFILE, payload);
        this.profile = camelcaseKeys(res.data.profile, { deep: true });
        return [null, this.profile];
      } catch (err) {
        console.error("Error on PUT /profile API, ", err);
        return [new Error(), null];
      }
    },
    serializeQuestionnaireProfileInfo(questProfileInfo: any): Omit<ProfileInfoQuestionnaire_PUT, "avatar"> {
      const payload: Omit<ProfileInfoQuestionnaire_PUT, "avatar"> = {
        account: { gender: Gender.CRAZY, firstName: "", lastName: "" },
        birthday: "",
        phoneNumber: "",
        weight: "",
        weightUnit: WeightUnit.KG,
        height: "",
        heightUnit: HeightUnit.CM,
      };
      payload.account.firstName = questProfileInfo.firstName;
      payload.account.lastName = questProfileInfo.lastName;
      payload.birthday = questProfileInfo.birthday;
      payload.phoneNumber = questProfileInfo.phoneNumber.e164;
      payload.weight = questProfileInfo.weight.value;
      payload.weightUnit = questProfileInfo.weight.weightUnit;

      if (questProfileInfo.height.heightUnit === HeightUnit.CM) {
        payload.height = questProfileInfo.height.height_Cm;
        payload.heightUnit = HeightUnit.CM;
      } else if (questProfileInfo.height.heightUnit === HeightUnit.INCHES) {
        payload.height = transformFeetInchesToInches(questProfileInfo.height.height_Imp);
        payload.heightUnit = HeightUnit.INCHES;
      }
      payload.account.gender = questProfileInfo.gender;
      return payload;
    },
    async updateMemberProfileQuestionnaireValues(userInformation: any) {
      const payload = this.serializeQuestionnaireProfileInfo(userInformation);

      try {
        const snakedPayload = snakecaseKeys({ profile: payload });
        // @ts-ignore
        if (!snakedPayload.profile.account.gender) snakedPayload.profile.account.gender = null; // * Handle Gender Non Binary as null

        const res = await axiosInstance.put(API.MEMBER_PROFILE, snakedPayload);
        this.profile = camelcaseKeys(res.data.profile, { deep: true });
        return [null, this.profile];
      } catch (err) {
        console.error(`Error on PUT /profile from Questionnaire, ${err}`);
        return [true, null];
      }
    },
    async updateMemberProfileAvatarOnly(avatar: AvatarPut) {
      try {
        const snakedPayload = snakecaseKeys({ profile: { avatar } });
        const res = await axiosInstance.put(API.MEMBER_PROFILE, snakedPayload);
        this.profile = camelcaseKeys(res.data.profile, { deep: true });
        return [null, this.profile];
      } catch (err) {
        console.error(`Error on PUT /profile from Questionnaire, ${err}`);
        return [true, null];
      }
    },
  },
});
