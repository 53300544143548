<script setup lang="ts">
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { RouteNameMenu } from "@/types/AuthTypes";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useLayoutStore } from "@/stores/layout";

const { navbarOpened, isMobileBrowser } = storeToRefs(useLayoutStore());
const router = useRouter();

const currRouteName = computed(() => {
  return router.currentRoute.value.name;
});

const isCurrentRouteMatched = (routeName: RouteNameMenu) => {
  const currentRoute = router.currentRoute.value;
  return currentRoute.matched.some((obj) => obj.name === routeName);
};

const toggleNavbarDrawer = () => {
  navbarOpened.value = !navbarOpened.value;
};

const closeNavbarDrawer = () => {
  if (!isMobileBrowser.value) return;
  navbarOpened.value = false;
};
</script>

<template>
  <div :class="{ 'navbar-container': true, 'mobile-browser': isMobileBrowser }" v-click-outside="closeNavbarDrawer">
    <nav class="navigation-bar" :class="{ closed: !navbarOpened }">
      <div class="section one">
        <div class="bioniq-logo">
          <div class="semicircle" @click="toggleNavbarDrawer">
            <img :class="{ rotated: !navbarOpened }" src="@/assets/logos/navbar/arrow.svg" alt="arrow" />
          </div>
          <img class="bioniq-logo-img" src="@/assets/logos/bioniq-logo-white.svg" alt="bioniq" />
        </div>
      </div>
      <div class="section two">
        <RouterLink :to="{ name: RouteNameMenu.MENU_ANALYTICS }">
          <div
            class="menu-item"
            :data-exact-route="currRouteName === RouteNameMenu.MENU_ANALYTICS"
            :class="{ active: currRouteName === RouteNameMenu.MENU_ANALYTICS }"
          >
            <div class="menu-item-logo">
              <img
                v-if="currRouteName === RouteNameMenu.MENU_ANALYTICS"
                src="@/assets/logos/navbar/blood-drop-black.svg"
                alt="dashboard-black"
              />
              <img v-else src="@/assets/logos/navbar/blood-drop.svg" alt="dashboard" />
            </div>
            <div v-if="navbarOpened" class="menu-text">{{ $t("NAVBAR.ANALYTICS") }}</div>
          </div>
        </RouterLink>
        <RouterLink :to="{ name: RouteNameMenu.MENU_FORMULA }">
          <div class="menu-item">
            <div class="menu-item-logo">
              <img
                v-if="currRouteName === RouteNameMenu.MENU_FORMULA"
                src="@/assets/logos/navbar/formula-black.svg"
                alt="formula-black"
              />
              <img v-else src="@/assets/logos/navbar/formula.svg" alt="formula" />
            </div>
            <div v-if="navbarOpened" class="menu-text">{{ $t("NAVBAR.FORMULA") }}</div>
          </div>
        </RouterLink>
      </div>
      <div class="section three">
        <RouterLink :to="{ name: RouteNameMenu.MENU_SETTINGS }" data-route="settings-link">
          <div class="menu-item">
            <div class="menu-item-logo">
              <img
                v-if="isCurrentRouteMatched(RouteNameMenu.MENU_SETTINGS)"
                src="@/assets/logos/navbar/settings-black.svg"
                alt="settings-black"
              />
              <img v-else src="@/assets/logos/navbar/settings.svg" alt="settings" />
            </div>
            <div v-if="navbarOpened" class="menu-text">{{ $t("NAVBAR.SETTINGS") }}</div>
          </div>
        </RouterLink>
      </div>
    </nav>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/navbars.css";

.navbar-container {
  height: calc(100% - var(--navbar-margin) * 2);
  padding: var(--navbar-margin) 0 var(--navbar-margin) var(--navbar-margin);
  display: flex;
  z-index: 3;
  background: linear-gradient(
    0deg,
    var(--clr-grey-bg-main) 0%,
    var(--clr-grey-bg-main) calc(100% - var(--layout-infobar-height)),
    rgba(255, 255, 255, 1) calc(100% - var(--layout-infobar-height)),
    rgba(255, 255, 255, 1) 100%
  );
}
.navbar-container.mobile-browser {
  position: fixed;
}
.navigation-bar {
  width: var(--navbar-width-opened);
  background-color: var(--clr-blue-3);
  color: var(--clr-white-1);
  padding: 3em var(--navbar-padding) 3em var(--navbar-padding);
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width var(--navbar-transition-dur) ease;
  box-sizing: border-box;
}

.section {
  display: flex;
  flex-direction: column;
}
.section a {
  text-decoration: none;
  overflow: hidden;
}
.section .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5em 0 0.5em 0;
  padding: 0.5em;

  border-radius: 0.5em;
  position: relative;
}
.section .menu-item-logo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
}
.section .menu-item-logo img {
  width: inherit;
  height: inherit;
}
.router-link-active .menu-item:not(.menu-item[data-exact-route="false"]) {
  background-color: var(--clr-white-1);
}
.router-link-active .menu-item[data-exact-route="false"] .menu-text {
  color: var(--clr-white-1);
}
.menu-item.active {
  background-color: var(--clr-white-1);
}
.menu-text {
  font-size: 1em;
  margin-left: 1em;
  color: var(--clr-white-1);
  text-decoration: none;
  white-space: nowrap;
}
.router-link-active .menu-text {
  color: var(--clr-black-1);
}
.section.one {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bioniq-logo {
  margin: 0.3em 0 0.3em 0;
  position: absolute;
  height: 1.5em;
  width: 3.5em;
  display: flex;
  align-items: center;
}
.bioniq-logo .semicircle {
  width: 1em;
  background-color: var(--clr-blue-3);
  position: absolute;
  padding: 0.3em 0em 0.3em 0;
  display: flex;
  height: 1.5em;

  right: -6.5em;
  border-radius: 0 1em 1em 0;
  cursor: pointer;
  transition: right var(--navbar-transition-dur) ease;
}
.bioniq-logo .semicircle img {
  width: 50%;
  transition: transform var(--navbar-transition-dur) ease;
  transform: rotate(180deg);
}
.bioniq-logo img {
  width: 100%;
}

.navigation-bar.closed {
  width: var(--navbar-width);
}
.navigation-bar.closed .bioniq-logo .semicircle {
  right: -1.7rem;
}
.semicircle img.rotated {
  transform: rotate(0deg);
}

@media (--max-tablet-width) {
  .navbar-container {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation-bar {
    width: var(--navbar-width-opened-phone);
    padding: 1em var(--navbar-padding-phone) 1em var(--navbar-padding-phone);
    border-radius: 0;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .section .menu-item {
    border-radius: 0.3em;
  }
  .section .menu-item-logo {
    width: 1.1em;
    height: 1.1em;
  }
  .bioniq-logo .bioniq-logo-img {
    display: none;
  }
  .bioniq-logo .semicircle {
    right: -4.5em;
  }
  .navigation-bar.closed {
    width: var(--navbar-width-phone);
  }
  .navigation-bar.closed .bioniq-logo .semicircle {
    right: -0.6rem;
  }
}
</style>
