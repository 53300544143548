<script setup lang="ts">
import { computed } from "vue";
import QuestionnaireLayout from "@/components/layout/QuestionnaireLayout.vue";
import { useQuestionnairesStore } from "@/stores/questionnaires";
import QuestionnaireSteps from "@/components/questionnaire/QuestionnaireSteps.vue";
import { questMapper } from "@/stores/questionnaires";
import type { RouteName } from "@/types/AuthTypes";

const questStore = useQuestionnairesStore();

const questionsLength = computed<number>(() => {
  return questStore.activeQuestionnaire!.questions.length;
});

const questionnaireName = computed<RouteName>(() => {
  return questMapper[questStore.activeQuestionnaire!.key]!.name;
});
</script>

<template>
  <QuestionnaireLayout>
    <QuestionnaireSteps :steps-nr="questionsLength" :questionnaire-name="questionnaireName" class="quest-nav" />
    <RouterView></RouterView>
  </QuestionnaireLayout>
</template>

<style scoped>
.quest-nav {
  margin-top: 1em;
  margin-bottom: 2em;
  row-gap: 1em;
}
</style>
