import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";
import { LOCALE, MESSAGE_PAGE_SIZE } from "@/util/constants";
import { i18n } from "@/includes/i18n-setup";

export interface BackgroundPicture {
  id: number;
  image: {
    originalUrl: string;
    thumbUrl: string;
  };
}
export interface MessageDetails {
  backgroundPicture: BackgroundPicture;
  createdAt: string;
  updatedAt: string;
  bodyDe: string;
  bodyEn: string;
  titleDe: string;
  titleEn: string;
  key: string;
}

export interface Message {
  id: number;
  sentAt: string;
  readAt: string;
  purposeId: number;
  purposeType: string;
  title: string;
  body: string;
  message: MessageDetails;
}

export const useMessagesStore = defineStore({
  id: "messages",
  state: () => ({
    messages: [] as Message[],
    totalCount: 0,
    currentPage: 1,
  }),
  getters: {
    getMessagePicture() {
      return (msg: Message) => (msg.message.backgroundPicture ? msg.message.backgroundPicture.image.thumbUrl : "");
    },
    getMessageTitle() {
      return (msg: Message) => (i18n.global.locale.value === LOCALE.DE ? msg.message.titleDe : msg.message.titleEn);
    },
    getMessageBody() {
      return (msg: Message) => (i18n.global.locale.value === LOCALE.DE ? msg.message.bodyDe : msg.message.bodyEn);
    },
  },
  actions: {
    async fetchMessages(): Promise<boolean> {
      try {
        const res = await axiosInstance.get(API.MESSAGES, {
          params: { page: this.currentPage, per: MESSAGE_PAGE_SIZE, "filters[read_at_blank]": true },
        });
        const data: { totalCount: number; receivedMessages: Message[] } = camelcaseKeys(res.data, { deep: true });

        this.totalCount = data.totalCount;
        this.messages = this.messages.concat(data.receivedMessages);
        return true;
      } catch (err) {
        console.error("Error calling the /received_messages API, ", err);
        return false;
      }
    },
    async markAllMessagesAsRead(): Promise<boolean> {
      try {
        await axiosInstance.post(API.MESSAGES_MARK_ALL_READ);
        return true;
      } catch (err) {
        console.error("Error calling the /received_messages/mark_as_read API, ", err);
        return false;
      }
    },
    async markMessageAsRead(messageId: number): Promise<boolean> {
      try {
        await axiosInstance.put(`${API.MESSAGES}/${messageId}`, { params: { action: "read", id: messageId } });
        return true;
      } catch (err) {
        console.error("Error calling the /received_messages API, ", err);
        return false;
      }
    },
  },
});
