import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";

export interface Supplement {
  supplement: {
    name: string;
    key: string;
  };
  dosage: number;
  basicDose: number;
  finalDose: number;
}

export interface Ingredient {
  ingredient: {
    name: string;
    key: string;
  };
  amountPerServing: number;
  unit: string;
  nrvPercent: string;
}

export interface Formulas {
  totalCount: number | null;
  supplementFormulas: SupplementFormulaShort[];
}

export enum SupplemenFormulaStatus {
  READY = "ready",
  APPROVED = "approved_for_production",
}
export enum SupplemenFormulaProductType {
  GO = "go",
  PRO = "pro",
}

export interface SupplementFormulaShort {
  id: number;
  recipePdf: string;
  status: SupplemenFormulaStatus;
  productType: SupplemenFormulaProductType;
  createdAt: string;
}

export interface SupplementFormulaFull {
  id: number;
  recipePdf: string;
  status: SupplemenFormulaStatus;
  productType: SupplemenFormulaProductType;
  createdAt: string;
  jarSize: number;
  supplements: Supplement[];
  ingredients: Ingredient[];
}

export interface FormulasComplete {
  [key: number]: SupplementFormulaFull;
}

export const useFormulasStore = defineStore({
  id: "formulas",
  state: () => ({
    // * completely replace object when setting new state
    formulas: {
      totalCount: null as number | null,
      supplementFormulas: [] as SupplementFormulaShort[],
    } as Formulas,
    formulasComplete: {} as FormulasComplete, // the key used for index here is the bloodTestId
  }),
  getters: {},
  actions: {
    async fetchFormulas(): Promise<[boolean, Formulas | null]> {
      if (this.formulas.totalCount !== null) return [false, this.formulas];
      try {
        const res = await axiosInstance.get(API.SUPPLEMENT_FORMULAS);
        const data: Formulas = camelcaseKeys(res.data, { deep: true });
        this.formulas.totalCount = data.totalCount;
        this.formulas.supplementFormulas = data.supplementFormulas;
        return [false, data];
      } catch (err) {
        console.error("Error on GET /supplement_formulas API, ", err);
        return [true, null];
      }
    },
    async fetchFormulaComplete(formulaId: number): Promise<[boolean, SupplementFormulaFull | null]> {
      const existingFormula = this.formulasComplete[formulaId];
      if (existingFormula) return [false, existingFormula];

      try {
        const res = await axiosInstance.get(API.SUPPLEMENT_FORMULA.replace("{id}", formulaId.toString()));
        const formula: SupplementFormulaFull = camelcaseKeys(res.data, { deep: true });
        this.formulasComplete[formulaId] = formula;
        return [false, formula];
      } catch (err) {
        console.error(`Error on GET /supplement-formula/${formulaId} `, err);
        return [true, null];
      }
    },
  },
});
