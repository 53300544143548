<script setup lang="ts">
import { watchEffect } from "vue";
import { CountStatus, useBloodTestsCredits } from "@/composables/useBloodTestsCredits";
import LoaderError from "@/components/ui/LoaderError.vue";

const props = defineProps<{
  apiLoading: boolean;
}>();

const emit = defineEmits<{
  (e: "fetchCredits"): void;
}>();

const { cardActionText, goToBuyCredits, countStatusClass, updateCountStatus, cardInfoText, countStatus } =
  useBloodTestsCredits();

watchEffect(updateCountStatus);

const fetchCredits = () => {
  if (props.apiLoading) return;
  emit("fetchCredits");
};

const actionBehavior = () => {
  if (countStatus.value === CountStatus.ERROR) return fetchCredits();
  else return goToBuyCredits();
};
</script>

<template>
  <div :class="['credits-container', countStatusClass]">
    <div class="info-text">{{ cardInfoText }}</div>
    <div class="line-container">
      <div class="line"></div>
      <div class="dots">
        <div class="dot" v-for="n in 3" :key="n"></div>
      </div>
      <div class="line"></div>
    </div>
    <div class="action-container">
      <div class="left-side" @click="actionBehavior">
        <div class="text">{{ cardActionText }}</div>
        <div class="arrow-icon">
          <img src="@/assets/icons/black_arrow.svg" alt="arrow" />
        </div>
      </div>
      <div class="right-side">
        <div v-if="countStatus === CountStatus.ERROR && props.apiLoading" class="loading-container">
          <LoaderError />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.credits-container {
  padding: 1.5em;
  border-radius: 1em;
  font-size: 0.9rem;
  margin-bottom: 1.5em;
  & .info-text {
    font-weight: bold;
  }
  & .line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 1em 0;
    & .line {
      height: 1px;
      width: 100%;
    }
    & .dots {
      display: flex;
      gap: 0.5em;
      justify-content: center;
      align-items: center;
      & .dot {
        width: 3px;
        height: 3px;
        border-radius: 100%;
      }
    }

  }
  & .action-container {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .left-side {
      display: flex;
      cursor: pointer;
      width: fit-content;
      & .text {
        color: var(--clr-black-1);
        padding-right: 0.2em;
      }
    }
    & .arrow-icon {
      width: 0.8em;
      transform: rotate(-90deg);
      & img {
        width: 100%;
      }
    }
  }
}
.credits-container.success {
  background-color: var(--clr-credits-bg-success);
  color: var(--clr-credits-high-success);
  & .line-container {
    & .line {
      background-color: var(--clr-credits-high-success);
    }
    & .dots {
      & .dot {
        background-color: var(--clr-credits-high-success);
      }
    }
  }
}
.credits-container.neutral {
  background-color: var(--clr-credits-bg-neutral);
  color: var(--clr-credits-high-neutral);
  & .line-container {
    & .line {
      background-color: var(--clr-credits-high-neutral);
    }
    & .dots {
      & .dot {
        background-color: var(--clr-credits-high-neutral);
      }
    }
  }
}
.credits-container.error {
  background-color: var(--clr-credits-bg-error);
  color: var(--clr-credits-high-error);
  & .line-container {
    & .line {
      background-color: var(--clr-credits-high-error);
    }
    & .dots {
      & .dot {
        background-color: var(--clr-credits-high-error);
      }
    }
  }
}
</style>
