<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useCheckupResultsStore } from "@/stores/checkup-results";
import ButtonMain from "../ui/ButtonMain.vue";
import CreditsCount from "@/components/blood-drop/CreditsCount.vue";
import { useBloodTestsCredits } from "@/composables/useBloodTestsCredits";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

const emit = defineEmits<{
  (e: "uploadFinished", error: boolean): void;
}>();

const emitUploadFinished = (err: boolean) => {
  emit("uploadFinished", err);
};

const checkupStore = useCheckupResultsStore();
const isFileUploadLoading = ref(true);
const isApiLoading = ref(true);

onMounted(() => attachUploadEvents());
onBeforeUnmount(() => removeUploadEvents());

// ----- Load browser -----
const uploadTestForm = ref<HTMLElement | null>(null);
const uploadFile = ref<any | null>(null);
const loadedFile = ref(null);

const updateFileLoadedStatus = (e: any) => {
  if (!e) return (loadedFile.value = null);
  loadedFile.value = e.target.files[0];
};

const attachUploadEvents = () => {
  if (!uploadTestForm.value || !uploadFile.value) return;
  uploadFile.value.addEventListener("change", updateFileLoadedStatus);
};

const removeUploadEvents = () => {
  updateFileLoadedStatus(null);
  if (!uploadTestForm.value || !uploadFile.value) return;
  uploadFile.value.removeEventListener("change", updateFileLoadedStatus);
};
// ----- Load browser -----

// ----- Upload -----
const uploadTest = async () => {
  if (!loadedFile.value) return;

  isFileUploadLoading.value = true;
  const [err, _res]: any[] = await checkupStore.uploadBloodTest(loadedFile.value);
  isFileUploadLoading.value = false;
  updateFileLoadedStatus(null);
  fetchCreditCount();

  return emitUploadFinished(!!err);
};
// ----- Upload -----

// ----- Credits Count -----
const { bloodTestUploadCredits } = useBloodTestsCredits();
const haveCredits = computed<boolean>(() => bloodTestUploadCredits.value !== null && bloodTestUploadCredits.value > 0);

const fetchCreditCount = async () => {
  isApiLoading.value = true;
  await checkupStore.getBloodTestsUploadCredits();
  isFileUploadLoading.value = false;
  isApiLoading.value = false;
};

fetchCreditCount();
// ----- Credits Count -----
</script>

<template>
  <div v-show="isFileUploadLoading" class="loading-container">
    <LoaderSpinner />
  </div>
  <div :class="{ 'upload-blood-test-modal-content': true, hidden: isFileUploadLoading }">
    <div class="top-content">
      <div class="bioniq-logo">
        <img src="@/assets/logos/bioniq-logo.svg" alt="bioniq logo" />
      </div>
      <div class="main-title">{{ $t("SECTION.BLOOD_DROP.UPLOAD.TITLE") }}</div>
      <div class="main-text">{{ $t("SECTION.BLOOD_DROP.UPLOAD.TEXT_DESCR") }}</div>
      <CreditsCount @fetchCredits="fetchCreditCount" :apiLoading="isApiLoading" />
      <form class="upload-option-form" enctype="multipart/form-data" ref="uploadTestForm">
        <label for="upload-file">
          <div :class="{ 'draggable-area': true, disabled: !haveCredits }" for="upload-file">
            <div v-if="!loadedFile" class="content">
              <div class="img-container">
                <img src="@/assets/img/upload-img.svg" alt="upload-file" />
              </div>
              <div class="text">{{ $t("SECTION.BLOOD_DROP.UPLOAD.SELECT_FILE") }}</div>
            </div>
            <div v-else class="loaded-file">
              <div class="img-file-uploaded">
                <img src="@/assets/img/file-uploaded.svg" alt="file-uploaded" />
              </div>
              <div class="text">{{ (loadedFile as any).name }}</div>
            </div>
          </div>
        </label>

        <input
          id="upload-file"
          name="upload-file"
          type="file"
          accept=".jpg, .png, .pdf"
          ref="uploadFile"
          hidden
          :disabled="!haveCredits"
        />
      </form>
    </div>
    <div class="bottom-content">
      <div class="upload-button-container">
        <ButtonMain @click="uploadTest" :disabled="!haveCredits || !loadedFile">
          <template v-slot:btnText> Upload Blood Test </template>
        </ButtonMain>
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
.upload-blood-test-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.upload-blood-test-modal-content.hidden {
  opacity: 0;
}
.bioniq-logo {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  margin-bottom: 1em;
  height: 2em;
  border-bottom: 1px solid black;
  & img {
    width: 100%;
    height: 100%;
  }
}
.main-title {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}
.main-text {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1.5em;
}
.upload-option-form {
  margin-bottom: 1em;
}
.draggable-area {
  width: 100%;
  height: 10em;
  border: 2px dashed var(--clr-grey-4);
  border-radius: 1em;
  cursor: pointer;
  background-color: var(--clr-blue-1-semi-verylow);
  display: flex;
  justify-content: center;

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    & .img-container {
      width: 4em;
      & img {
        width: 100%;
      }
    }
    & .text {
      max-width: 15em;
      gap: 1em;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
  & .loaded-file {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    & .img-file-uploaded {
      width: 4em;
      height: 4em;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & .text {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}
.draggable-area.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.upload-button-container {
  max-width: 15em;
  margin: 0 auto;
  height: 3em;
  & :deep(.button-container) {
    background-color: var(--clr-blue-bioniq);
  }
  & :deep(.btn-text) {
    font-size: 1rem;
  }
}
.bottom-content {
  padding-bottom: 1em;
}
</style>
