<script setup lang="ts">
import { ref, computed } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { validatePassword } from "@/util/validations/authValidations";
import ButtonMain from "@/components/ui/ButtonMain.vue";
import { useAuthStore } from "@/stores/auth";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import { useRoute } from "vue-router";

const password = ref("");
const passwordWrong = ref(false);
const memberTriedToLoginAtLeastOnce = ref(false);
const authStore = useAuthStore();
const route = useRoute();

const isPasswordValid = computed<boolean>(() => {
  return validatePassword(password.value) && !passwordWrong.value;
});

const handlePasswordUpdate = (value: string) => {
  if (passwordWrong.value) passwordWrong.value = false;
  password.value = value;
};

const handleForgotPassword = () => {
  authStore.redirectTo(RouteName.AUTH_EMAIL_FP);
};

const isInputErrored = (val: boolean) => {
  return !val && memberTriedToLoginAtLeastOnce.value;
};

const handleNext = async () => {
  memberTriedToLoginAtLeastOnce.value = true;
  if (!isPasswordValid.value) return;

  const [err, success] = await authStore.postSignIn(password.value);
  if (err) passwordWrong.value = true;
  if (success) authStore.redirectTo(RouteNameMenu.MENU_ANALYTICS, route.query);
  return;
};

const handleMagicLink = async () => {
  const [err, success] = await authStore.postSignIn();
  if (err) return authStore.redirectTo(RouteName.AUTH_ERROR_MAGIC);
  if (success) authStore.redirectTo(RouteName.AUTH_SIGN_IN_CONFIRM);
};
</script>

<template>
  <div class="auth-password">
    <form @submit.prevent="handleNext" novalidate>
      <div class="password-input">
        <TextInputBox
          :inputName="'password'"
          :labelText="$t('LOGIN.PASSWORD')"
          :value="password"
          @update="handlePasswordUpdate"
          :errored="isInputErrored(isPasswordValid)"
          :disabled="false"
        />
        <div class="forget-password-cont">
          <span @click="handleForgotPassword">{{ $t("LOGIN.FORGOT_PASSWORD") }} ?</span>
        </div>
      </div>

      <div class="next-button">
        <ButtonMain @click="handleNext">
          <template v-slot:btnText>{{ $t("LOGIN.LOGIN") }}</template>
        </ButtonMain>
      </div>
    </form>
    <div class="magic-link-text">{{ $t("LOGIN.MAGIC_LINK_TEXT") }}</div>
    <div class="magic-link-btn">
      <ButtonMain @click="handleMagicLink">
        <template v-slot:btnText>{{ $t("LOGIN.MAGIC_LINK_BTN") }}</template>
      </ButtonMain>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.password-input {
  margin-bottom: 2rem;
}
.next-button {
  height: 3em;
}

.forget-password-cont {
  display: flex;
  justify-content: flex-end;
}
.forget-password-cont span {
  font-size: 0.8em;
  padding: 0.5em;
  cursor: pointer;
  color: var(--clr-black-1);
  text-decoration: underline;
}
.magic-link-text {
  margin: 2em;
  text-align: center;
}
.magic-link-btn {
  height: 3em;
}
.magic-link-btn :deep(.button-container) {
  background-color: var(--clr-white-1);
  border: 2px solid var(--clr-black-1);
}
.magic-link-btn :deep(.btn-text) {
  color: var(--clr-black-1);
}
</style>
