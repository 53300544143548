export enum LOCALE {
  DE = "de",
  EN = "en",
  RU = "ru",
}
export const DEFAULT_LOCALE = LOCALE.EN;

export enum Currency {
  EUR = "EUR",
}

export const APP_STORE_URL = {
  android: "https://play.google.com/store/apps/details?id=com.vaha.app",
  ios: "https://apps.apple.com/app/vaha/id1484631962",
};

export const BUY_UPLOAD_BLOOD_TEST_CREDITS_LINKS = {
  development: "https://cart-staging.bioniq.com/cart/bloodtest-upload",
  staging: "https://cart-staging.bioniq.com/cart/bloodtest-upload",
  production: "https://cart.bioniq.com/cart/bloodtest-upload",
};

export const MESSAGE_PAGE_SIZE = 10;

export enum ProfileInfoMandatoryKeys {
  AVATAR = "avatar",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PH_NUMBER = "phoneNumber",
  GENDER = "gender",
  BIRTHDAY = "birthday",
  HEIGHT = "height",
  WEIGHT = "weight",
}
