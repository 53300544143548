import { ref, reactive, type Ref, watch } from "vue";
import { isClickBelowMiddleHorizontalLineOfCont } from "@/util/dom/functions";
import { useEventListener } from "@/composables/useEventListener";

export function usePopupBox(teleportTo: string, clickEl: Ref<HTMLElement>, hasStaticPosition: boolean) {
  const classDown = ref<boolean>(true);
  const clickPosition = reactive({ x: 0, y: 0 });
  const showPopupBox = ref(false);
  const teleportHTMLElementRect = ref<any>(null);

  watch(
    () => clickEl.value,
    (newVal) => {
      if (!newVal) return;
      teleportHTMLElementRect.value = document.querySelector(teleportTo)?.getBoundingClientRect();
      if (hasStaticPosition) findExcludedContainerStartingPos();
    }
  );

  const updateClickPosition = (event: MouseEvent) => {
    clickPosition.x = event.clientX;
    clickPosition.y = event.clientY;
  };

  const togglePopupBox = (event: MouseEvent) => {
    if (!hasStaticPosition) updateClickPosition(event);
    classDown.value = isClickBelowMiddleHorizontalLineOfCont(event, teleportHTMLElementRect.value);

    showPopupBox.value = !showPopupBox.value;
  };

  const closePopupBox = () => {
    if (!showPopupBox.value) return;
    showPopupBox.value = false;
  };

  const findExcludedContainerStartingPos = () => {
    const elRect = clickEl.value!.getBoundingClientRect();
    clickPosition.x = elRect.x + elRect.width / 2;
    clickPosition.y = elRect.y + elRect.width / 2 + 5;
  };

  useEventListener(window, "resize", findExcludedContainerStartingPos);

  return { showPopupBox, togglePopupBox, closePopupBox, clickPosition, classDown };
}
