<script setup lang="ts">
import { computed } from "vue";
import { RouteDisplayNames, type RouteNameMenu } from "@/types/AuthTypes";
import { useRouter } from "vue-router";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import NotificationIcon from "@/components/ui/NotificationIcon.vue";
import { storeToRefs } from "pinia";
import { useLayoutStore } from "@/stores/layout";

const router = useRouter();
const { navbarOpened } = storeToRefs(useLayoutStore());

const infoRouteName = computed<string>(() => {
  const currRouteName = router.currentRoute.value.name;

  let indexKey = currRouteName as RouteNameMenu;
  if (Object.keys(RouteDisplayNames).includes(currRouteName as RouteNameMenu)) return RouteDisplayNames[indexKey];

  const matchedVal = router.currentRoute.value.matched.find((obj) => {
    return Object.keys(RouteDisplayNames).includes(obj.name as string);
  });
  if (matchedVal) {
    indexKey = matchedVal.name as RouteNameMenu;
    return RouteDisplayNames[indexKey];
  }

  return RouteDisplayNames.analytics;
});
</script>

<template>
  <div class="infobar-container">
    <div :class="{ 'route-name': true, 'navbar-closed': !navbarOpened }" ref="routeName">{{ $t(infoRouteName) }}</div>
    <div class="content-right">
      <div class="notifications">
        <NotificationIcon />
      </div>
      <UserAvatar class="infobar" />
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/layout.css";
@import "@/assets/styles/navbars.css";

.infobar-container {
  height: var(--layout-infobar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  box-shadow: rgba(33, 35, 38, 0.2) 0px 20px 20px -10px;
}
.route-name {
  transition: margin-left var(--navbar-transition-dur) ease;
  font-size: 2.4rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  margin-left: calc(var(--navbar-padding) + var(--navbar-width-opened) + var(--layout-padding));
}
.route-name.navbar-closed {
  margin-left: calc(var(--navbar-padding) + var(--navbar-width) + var(--layout-padding));
}
.notifications {
  padding: 0.5em;
  z-index: 2;
}
.content-right {
  display: flex;
  align-items: center;
  margin-right: 1em;
  z-index: 2;
  height: inherit;
}

@media (--max-tablet-width) {
  .infobar-container {
    height: var(--layout-infobar-height-phone);
  }
  .search-bar .search-logo {
    padding: 0.3em;
  }
  .notifications {
    padding: 0.3em;
  }
  .search-bar .search-logo {
    height: var(--infobar-icon-width-phone);
    width: var(--infobar-icon-width-phone);
  }
  .route-name {
    font-size: 1.5rem;
    margin-left: calc(var(--navbar-padding-phone) + var(--navbar-width-opened-phone) + var(--layout-padding-phone));
  }
  .route-name.navbar-closed {
    margin-left: calc(var(--navbar-padding-phone) + var(--navbar-width) + var(--layout-padding-phone));
  }
}

@media (--max-phone-width) {
  .infobar-container {
    justify-content: end;
  }
  .route-name {
    display: none;
  }
  .search-bar .bar {
    width: 7.5rem;
  }
}
</style>
