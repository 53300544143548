import { fetchLocale, addLanguageToI18N } from "@/includes/i18n-setup";
import { LOCALE, DEFAULT_LOCALE } from "@/util/constants";
import { i18n } from "@/includes/i18n-setup";

export const getBrowserLocale = () => {
  const locale = navigator.language.split("-")[0].toLowerCase() as LOCALE;
  if (!Object.values(LOCALE).includes(locale)) return DEFAULT_LOCALE;
  return locale;
};

export const changeAppLocale = (locale: LOCALE) => {
  i18n.global.locale.value = locale;
};

export const changeLanguageToBrowserLocale = async () => {
  const browserLocale = getBrowserLocale();

  const res = await fetchLocale(browserLocale);
  addLanguageToI18N(browserLocale, res);
  changeAppLocale(browserLocale);
};

export const getAppLocale = (queryLocale: string | undefined) => {
  if (queryLocale === "de") return LOCALE.DE;
  if (queryLocale === "ru") return LOCALE.RU;
  return LOCALE.EN;
};
