<script setup lang="ts">
const props = defineProps<{
  type: "danger" | "info";
}>();

const emit = defineEmits<{
  (e: "yes"): void;
  (e: "no"): void;
}>();
</script>

<template>
  <div :class="['confirm-container', props.type]">
    <div class="modal-title">
      <slot name="title"></slot>
    </div>
    <div class="buttons">
      <div class="confirm-btn yes" @click="emit('yes')">
        <slot name="yes"></slot>
      </div>
      <div class="confirm-btn no" @click="emit('no')">
        <slot name="no"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
.confirm-container {
  & .modal-title {
    font-size: 1.1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  & .buttons {
    margin-top: 1.5em;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  & .confirm-btn {
    min-width: 4em;
    cursor: pointer;
    padding: 1em;
    border-radius: 0.5em;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  & .confirm-btn.yes {
    color: var(--clr-white-1);
  }
  & .confirm-btn.yes:hover {
    color: var(--clr-white-1);
  }
  & .confirm-btn.no {
    background-color: var(--clr-white-1);
  }
  & .confirm-btn.no:hover {
    background-color: var(--clr-grey-1);
  }
}
.confirm-container.danger {
  & .confirm-btn.yes {
    background-color: var(--clr-red-3);
  }
  & .confirm-btn.yes:hover {
    background-color: var(--clr-red-3-hover);
  }
  & .confirm-btn.no {
    color: var(--clr-red-3);
  }
  & .confirm-btn.no:hover {
    background-color: var(--clr-grey-1);
  }
}
</style>
