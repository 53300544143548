const isClickBelowMiddleHorizontalLineOfCont = (event: any, refContainer: any) => {
  const bgContainer = {
    width: refContainer.width,
    height: refContainer.height + refContainer.top,
  };
  const click = { clickX: event.clientX, clickY: event.clientY - refContainer.top };
  return click.clickY <= Math.floor(bgContainer.height / 2);
};

const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
};

export { isClickBelowMiddleHorizontalLineOfCont, isMobileBrowser };
