<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import { RouteName } from "@/types/AuthTypes";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";
import { isMobileBrowser } from "@/util/functions";
import MagicLinkMobile from "@/components/layout/MagicLinkMobile.vue";

const authStore = useAuthStore();
const route = useRoute();
const loading = ref(true);

const isMobile = computed<boolean>(() => {
  return isMobileBrowser();
});

const confirmSignUp = async (token: string) => {
  const [err, res]: any[] = await authStore.confirmSignUpEmail(token);
  loading.value = false;

  if (err) return authStore.redirectTo(RouteName.AUTH_ERROR_CONFIRM);

  if (res.passwordMustBeReset && res.resetToken)
    authStore.redirectTo(RouteName.AUTH_PASS_NEW, { token: res.resetToken });
  if (!res.passwordMustBeReset) authStore.redirectTo(RouteName.AUTH_SUCCESS_CONFIRM);
};

const confirmEmailChange = async (token: string) => {
  const [err, _res]: any[] = await authStore.confirmEmailChange(token);
  loading.value = false;

  if (err) return authStore.redirectTo(RouteName.AUTH_ERROR_CONFIRM);
  authStore.redirectTo(RouteName.AUTH_SUCCESS_CONFIRM);
};

const handleDesktop = async () => {
  const token = route.query.token as string;
  if (route.meta.signUp) return confirmSignUp(token);
  if (route.meta.confirmEmail) return confirmEmailChange(token);
};

const handleMobileOrDesktop = () => {
  if (isMobile.value) return (loading.value = false);
  handleDesktop();
};

handleMobileOrDesktop();
</script>

<template>
  <div class="auth-email-confirming">
    <div class="loader-container" v-if="loading">
      <LoaderSpinner />
    </div>
    <MagicLinkMobile v-if="isMobile && !loading"></MagicLinkMobile>
  </div>
</template>

<style scoped>
.auth-email-confirming {
  height: 100vh;
  display: flex;
}
.loader-container {
  position: relative;
  width: 100%;
}
</style>
